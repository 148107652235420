<template>
  <div class="wrapper resumeIndex">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="" name="1">
              <template slot="title">
                <div class="subTit">
                  <el-icon class="el-icon-files"> </el-icon>快捷菜单
                </div>
              </template>
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                  <span
                    v-for="i in dataCount"
                    :key="i.type"
                    style="float: right; margin-right: 15px"
                  >
                    <span v-if="item.name == i.type">{{ i.numberText }}</span>
                  </span>
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="2">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-collection-tag"></el-icon>订阅搜索
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'order')"
                  v-for="item in OrderSearchList"
                  :class="actived == item.id ? 'actived' : ''"
                  :key="item.id"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <span
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    :title="item.name"
                  >
                    {{ item.name }}</span
                  >

                  <span
                    class="hits"
                    style="display: block; margin-right: 15px"
                    >{{ item.hits }}</span
                  >
                  <div class="closed" @click.stop="delSearch(item.id)">
                    &times;
                  </div>
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="3">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-folder-opened"></el-icon>文件夹
                  <div class="edit" @click.stop="editFavorite">编辑</div>
                </div></template
              >
              <el-collapse v-model="activeNames1">
                <el-collapse-item
                  name="1"
                  v-if="myFileData && myFileData.length > 0"
                >
                  <template slot="title"
                    ><div class="subTit_wj">
                      <i class="iconfont icon-lnav_wdwj"></i>我的文件夹
                    </div></template
                  >
                  <ul class="menuList">
                    <li
                      @click="searchCus(item.id, 'folder')"
                      v-for="item in myFileData"
                      :class="actived == item.id ? 'actived' : ''"
                      :key="item.id"
                    >
                      {{ item.name }}
                      <span style="float: right; margin-right: 15px">
                        <span>{{ item.counts }}</span>
                      </span>
                    </li>
                  </ul>
                </el-collapse-item>
                <el-collapse-item
                  name="2"
                  v-if="shareData && shareData.length > 0"
                >
                  <template slot="title"
                    ><div class="subTit_wj">
                      <i class="iconfont icon-rnav_gxwj"></i>共享文件夹
                    </div></template
                  >
                  <ul class="menuList">
                    <li
                      @click="searchCus(item.id, 'folder')"
                      v-for="item in shareData"
                      :class="actived == item.id ? 'actived' : ''"
                      :key="item.id"
                    >
                      {{ item.name }}
                      <span style="float: right; margin-right: 15px">
                        <span>{{ item.counts }}</span>
                      </span>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
            <el-collapse-item title="" name="4">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-collection-tag"></el-icon>个性化标签
                  <div class="edit" @click.stop="editPersonalizedTags">
                    编辑
                  </div>
                </div></template
              >
              <ul class="menuList">
                <li
                  v-for="(item, index) in personalizedTags.list"
                  :key="index"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                  @click="searchCus(item.id, 'label')"
                >
                  <span
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    :title="item.name"
                  >
                    {{ item.name }}</span
                  >

                  <span style="display: block; margin-right: 15px">{{
                    item.hits
                  }}</span>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix"
        :class="collapsed ? 'screen' : ''"
        style="margin-bottom: 15px"
      >
        <el-form
          style="margin: 0"
          @submit.native.prevent
          :model="searchInfo"
          ref="filter"
        >
          <el-row
            :gutter="24"
            style="width: 100%; margin: 0; background-color: #fff"
            class="searchBox"
          >
            <el-row :span="24" style="display: flex">
              <el-col
                class="searchRow"
                style="
                  padding: 20px 15px 0 15px !important;
                  display: flex;
                  align-items: self-start;
                "
              >
                <div style="width: calc(100% - 150px); position: relative">
                  <el-input
                    class="input-with-select search"
                    type="text"
                    v-model="searchInfo.searchKey"
                    @change="trimInput"
                    maxlength="50"
                    placeholder="请输入关键词，多个词之间请用空格隔开"
                    @keyup.enter.native="onInputBlur($event), submitForm()"
                    @focus="focusShowStorage('focus')"
                    @blur="onInputBlur($event)"
                    style="width: 100%"
                  >
                    <el-select
                      v-model="searchInfo.matchAll"
                      slot="prepend"
                      placeholder="请选择"
                      style="color: #606266; font-size: 13px"
                    >
                      <el-option
                        style="color: #606266; font-size: 13px"
                        label="全部匹配"
                        :value="true"
                      ></el-option>
                      <el-option
                        style="color: #606266; font-size: 13px"
                        label="任意匹配"
                        :value="false"
                      ></el-option>
                    </el-select>
                    <el-button
                      type="primary"
                      slot="append"
                      @click.stop="
                        (searchInfo.majorSearch = false), submitForm()
                      "
                      >搜 索</el-button
                    >
                  </el-input>
                  <div
                    ref="showFocus"
                    v-show="isShowFocus"
                    class="loacalStorage"
                    style="padding-bottom: 15px; border-radius: 0 0 5px 5px"
                  >
                    <div class="clearfix" style="margin: 15px 15px 0">
                      <span
                        style="
                          float: left;
                          color: rgb(153, 153, 153);
                          font-size: 12px;
                          margin-bottom: 5px;
                        "
                        ><i class="el-icon-refresh-right"></i>
                        历史搜索条件</span
                      >
                      <span
                        style="
                          float: right;
                          color: rgb(41, 121, 255);
                          font-size: 12px;
                          cursor: pointer;
                        "
                        @click="clearLocal($event)"
                        >清除历史记录</span
                      >
                    </div>
                    <span
                      class="btnHover text-overflows"
                      type="text"
                      style="
                        color: rgb(51, 51, 51);
                        display: block;
                        padding: 5px 30px;
                        margin-left: 0;
                        white-space: pre;
                      "
                      v-for="(item, index) in saveSearchVal"
                      :key="index"
                      @click="clickSearch(item)"
                      >{{ item }}</span
                    >
                  </div>
                </div>
                <el-button
                  style="
                    padding: 10px;
                    border-radius: 5px;
                    width: 140px;
                    float: right;
                    margin-left: 4px;
                  "
                  @click="(searchInfo.majorSearch = true), submitForm()"
                >
                  关键内容搜索
                  <el-popover
                    placement="bottom-start"
                    trigger="hover"
                    popper-class="quickPipeline"
                  >
                    <div class="">
                      <div class="title">提示：</div>
                      <div class="content">
                        关键内容搜索指候选人基本信息中的职能、工作经历中的职位名、现居住地、
                        <br />
                        期望城市、期望职位、公司名称、项目名称、学校名称几个字段的内容搜索。
                      </div>
                    </div>
                    <i
                      class="iconfont icon-wenhao-xiangsu"
                      style="font-size: 14px; margin-left: 4px"
                      slot="reference"
                    ></i>
                  </el-popover>
                </el-button>
              </el-col>
              <el-col
                style="
                  width: 320px;
                  line-height: 35px;
                  text-align: justify;
                  padding: 20px 15px 15px 15px !important;
                  padding-right: 0;
                "
              >
                <span
                  size="small"
                  class="hoverColorChange"
                  style="
                    text-align: left;
                    color: #606266;
                    font-size: 13px !important;
                    display: inline-block;
                  "
                  type="text"
                  @click="linkToWebSearch"
                >
                  全网搜
                </span>
                <el-divider direction="vertical"></el-divider>
                <span
                  class="order"
                  @click="orderSearch"
                  style="font-size: 13px !important"
                  >订阅搜索</span
                >
                <el-divider direction="vertical"></el-divider>
                <span
                  @click="isMoreItemShow = !isMoreItemShow"
                  class="showMore hoverColorChange"
                  >{{ isMoreItemShow ? "收起" : "展开" }}更多条件<i
                    :class="{
                      'el-icon-arrow-up': isMoreItemShow,
                      'el-icon-arrow-down': !isMoreItemShow,
                    }"
                    style="padding-left: 5px"
                  ></i
                ></span>
              </el-col>
            </el-row>
            <el-col
              :span="24"
              class="clearfix"
              style="padding: 0"
              v-if="SearchplanList.length"
            >
              <el-col :span="24" style="padding: 0">
                <el-form>
                  <el-form-item
                    label="保存的搜索条件："
                    label-width="125px"
                    :class="{
                      savedSearchLabel: !isMoreShow,
                      savedSearchLabel1: isMoreShow,
                    }"
                    style="margin-bottom: 0"
                  >
                    <el-radio-group
                      class="radioGroupBtn"
                      size="mini"
                      v-model="radioGroup"
                      :class="{
                        savedSearchLabel: !isMoreShow,
                        savedSearchLabel1: isMoreShow,
                      }"
                    >
                      <el-radio-button
                        class="hoverStyle"
                        style="margin-bottom: 15px"
                        :title="i.createName"
                        @click.native.prevent="
                          radioClick('', i.id, i.name, idx, i.create)
                        "
                        v-for="(i, idx) in SearchplanList"
                        :key="i.id"
                        :label="i.name"
                      >
                        <span
                          :style="{ 'font-weight': i.create ? 'bold' : '' }"
                          >{{ i.name }}</span
                        >
                        <i
                          class="el-icon-share hoverIcon"
                          style="margin-left: 10px"
                          @click.stop.prevent="share(i.id)"
                        ></i>
                        <i
                          class="el-icon-close hoverIcon"
                          style="margin-left: 3px"
                          @click.stop.prevent="delSearchPlan(i)"
                        ></i>
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="4" style="padding: 0; padding-right: 20px">
                <span
                  @click="isMoreShow = !isMoreShow"
                  class="showMore fr hoverColorChange"
                  >{{ isMoreShow ? "收起" : "显示" }}更多条件<i
                    :class="{
                      'el-icon-arrow-up': isMoreShow,
                      'el-icon-arrow-down': !isMoreShow,
                    }"
                    style="padding-left: 5px"
                  ></i
                ></span>
              </el-col>
            </el-col>
            <el-col v-else style="margin-bottom: 20px"></el-col>
            <el-col
              class="searchPanel1"
              style="
                padding-left: 0;
                padding-right: 0;
                background: #fafafa;
                border-top: 1px #eee solid;
                border-bottom: 1px #eee solid;
              "
              v-loading="searchPanelLoading"
            >
              <el-form
                label-width="110px"
                :model="searchInfo"
                ref="searchInfo"
                class="searchInfo"
                :rules="rulesOfSearch"
              >
                <el-col :span="24" style="padding-left: 0">
                  <el-col :span="24" style="padding: 0">
                    <el-form-item
                      label="现居住城市："
                      prop="nowLocations"
                      style="margin: 10px 0 0; height: 32px"
                    >
                      <city-choose
                        v-model="searchInfo.nowLocations"
                        @mainSearch="
                          submitForm('现居住城市', searchInfo.nowLocations)
                        "
                      ></city-choose>
                      <el-popover
                        placement="bottom"
                        trigger="click"
                        popper-class="moreCity"
                      >
                        <el-cascader
                          :key="cascaderCityKey"
                          filterable
                          clearable
                          size="mini"
                          style="width: 260px; height: 34px"
                          @change="handleChangeNowLocations($event)"
                          v-model="searchInfo.nowLocations"
                          :show-all-levels="false"
                          :options="ColumnCityList"
                          collapse-tags
                          :props="cityProps"
                        ></el-cascader>
                        <span
                          style="margin-left: 15px"
                          slot="reference"
                          class="hoverColorChange"
                          @click="(isMoreCity = !isMoreCity), isMoreCityClk()"
                          >更多
                          <i
                            :class="{
                              'el-icon-arrow-down': !isMoreCity,
                              'el-icon-arrow-up': isMoreCity,
                            }"
                          ></i
                        ></span>
                      </el-popover>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col style="padding-left: 0">
                  <el-form-item
                    label="工作年限："
                    prop="workExperiences"
                    style="margin-bottom: 0; height: 32px"
                  >
                    <el-checkbox-group
                      class="radioGroupBtn"
                      size="mini"
                      v-model="searchInfo.workExperiences"
                      @change="workExperienceChanged($event)"
                      text-color="#e60012"
                    >
                      <el-checkbox-button label="UnLimit"
                        >不限</el-checkbox-button
                      >
                      <el-checkbox-button label="OneToThree"
                        >1-3年</el-checkbox-button
                      >
                      <el-checkbox-button label="ThreeToFive"
                        >3-5年</el-checkbox-button
                      >
                      <el-checkbox-button label="FiveToTen"
                        >5-10年</el-checkbox-button
                      >
                      <el-checkbox-button label="TenMore"
                        >10年以上</el-checkbox-button
                      >
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
                <el-col style="padding-left: 0; margin-bottom: 10px" :span="20">
                  <el-form-item
                    label="教育经历："
                    prop="educations"
                    style="margin: 0; height: 32px"
                  >
                    <el-checkbox-group
                      class="radioGroupBtn"
                      size="mini"
                      v-model="searchInfo.educations"
                      @change="educationsChanged($event)"
                      text-color="#e60012"
                    >
                      <el-checkbox-button label="UnLimit"
                        >不限</el-checkbox-button
                      >
                      <el-checkbox-button
                        label="本科及以上"
                      ></el-checkbox-button>
                      <el-checkbox-button
                        label="硕士及以上"
                      ></el-checkbox-button>
                      <el-checkbox-button
                        label="博士及以上"
                      ></el-checkbox-button>
                      <el-checkbox-button
                        label="大专及以上"
                      ></el-checkbox-button>
                      <el-checkbox-button
                        label="高中及以下"
                      ></el-checkbox-button>
                    </el-checkbox-group>
                    <el-checkbox-group
                      class="radioGroupBtn"
                      size="mini"
                      v-model="searchInfo.checkSchoolType"
                      @change="educationsChanged($event, 'checkSchoolType')"
                      text-color="#e60012"
                    >
                      <el-checkbox-button label="985/211"></el-checkbox-button>
                    </el-checkbox-group>
                    <el-checkbox-group
                      class="radioGroupBtn"
                      size="mini"
                      v-model="searchInfo.is_tongzhao"
                      @change="educationsChanged($event, 'is_tongzhao')"
                      text-color="#e60012"
                    >
                      <el-checkbox-button label="统招"></el-checkbox-button>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>

                <el-col
                  :span="24"
                  style="padding: 0 0 20px 0; width: calc(100% - 24px)"
                >
                  <el-col :span="8" class="labelAppend">
                    <el-form-item label="姓名：" prop="userName">
                      <el-input
                        type="text"
                        @focus="isShowName = true"
                        @blur="blurUser($event)"
                        style="min-width: 180px; border-radius: 5px"
                        v-model.trim="searchInfo.userName"
                        placeholder="支持模糊搜索"
                        @keydown.enter.native="
                          submitForm('姓名', searchInfo.userName, 'enter')
                        "
                      >
                        <div
                          v-show="isShowName"
                          class="changeButtonStyle"
                          slot="append"
                        >
                          <el-button
                            icon="el-icon-search"
                            @click="submitForm('姓名', searchInfo.userName)"
                          ></el-button>
                        </div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" class="labelAppend">
                    <el-form-item label="联系方式：" prop="contact">
                      <el-input
                        v-model.trim="searchInfo.contact"
                        @focus="isShowContact = true"
                        @blur="blurContact($event)"
                        @keydown.enter.native="
                          submitForm('联系方式', searchInfo.contact, 'enter')
                        "
                      >
                        <div
                          v-show="isShowContact"
                          class="changeButtonStyle"
                          slot="append"
                        >
                          <el-button
                            icon="el-icon-search"
                            @click="submitForm('联系方式', searchInfo.contact)"
                          ></el-button>
                        </div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="行业：" prop="vocationIds">
                      <el-cascader
                        :key="isResouceShow"
                        style="width: 100%; height: 34px; min-width: 180px"
                        clearable
                        size="mini"
                        filterable
                        :show-all-levels="false"
                        :options="ColumnInList"
                        collapse-tags
                        v-model="searchInfo.vocationIds"
                        @change="submitForm('行业', searchInfo.vocationIds)"
                        :props="props"
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" style="height: 49px">
                    <el-form-item label="职能：" prop="functionIds">
                      <el-cascader
                        :key="isResouceShow"
                        style="width: 100%; min-width: 180px"
                        clearable
                        @change="submitForm('职能', searchInfo.functionIds)"
                        size="mini"
                        filterable
                        :show-all-levels="false"
                        :options="ColumnPosList"
                        collapse-tags
                        v-model="searchInfo.functionIds"
                        :props="props"
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="职位名称：" prop="title">
                      <el-input
                        @focus="isShowTitle = true"
                        @blur="blurTitle($event)"
                        class="input-with-select appandSelect"
                        v-model.trim="searchInfo.title"
                        style="
                          width: calc(100%);
                          float: left;
                          position: relative;
                        "
                        placeholder="支持模糊搜索"
                        @keydown.enter.native="
                          submitForm('职位名称', searchInfo.title, 'enter')
                        "
                      >
                        <el-select
                          v-model="searchInfo.isAllTitle"
                          slot="prepend"
                          placeholder="请选择"
                        >
                          <el-option
                            label="当前职位"
                            :value="false"
                          ></el-option>
                          <el-option label="全部职位" :value="true"></el-option>
                        </el-select>
                      </el-input>
                      <el-button
                        v-show="isShowTitle"
                        style="
                          padding: 9px;
                          background: #ff3f3f;
                          color: white;
                          position: absolute;
                          right: 0;
                          /* top: -1px; */
                        "
                        class="changeButtonStyle"
                        icon="el-icon-search"
                        @click="submitForm('职位名称', searchInfo.title)"
                      ></el-button>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="公司名称：" prop="company">
                      <el-input
                        @focus="isShowCompany = true"
                        @blur="blurCompany($event)"
                        class="input-with-select appandSelect"
                        v-model="searchInfo.company"
                        style="
                          width: calc(100%);
                          float: left;
                          position: relative;
                        "
                        placeholder="支持模糊搜索"
                        @keydown.enter.native="
                          submitForm('公司名称', searchInfo.company, 'enter')
                        "
                      >
                        <el-select
                          v-model="searchInfo.isAllCompany"
                          slot="prepend"
                          placeholder="请选择"
                        >
                          <el-option
                            label="当前公司"
                            :value="false"
                          ></el-option>
                          <el-option label="全部公司" :value="true"></el-option>
                        </el-select>
                      </el-input>
                      <el-button
                        v-show="isShowCompany"
                        style="
                          padding: 9px;
                          background: #ff3f3f;
                          color: white;
                          position: absolute;
                          right: 0;
                          top: -1px;
                        "
                        icon="el-icon-search"
                        @click="submitForm('公司名称', searchInfo.company)"
                      ></el-button>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="年龄："
                      class="delFocus"
                      style="min-width: 280px; margin-bottom: 0 !important"
                    >
                      <el-form-item
                        label-width="0"
                        prop="minAge"
                        style="
                          width: 46%;
                          display: inline-block;
                          margin-bottom: 0;
                        "
                      >
                        <el-input
                          @focus="isShowAge = true"
                          @blur="blurAge($event)"
                          class="appendInput"
                          v-model.trim.number="searchInfo.minAge"
                          @keyup.enter.native="
                            submitForm('年龄', searchInfo.minAge, 'enter')
                          "
                          ><span slot="suffix">岁</span></el-input
                        >
                      </el-form-item>
                      <span
                        style="
                          display: inline-block;
                          height: 35px;
                          line-height: 35px;
                          width: 8%;
                          text-align: center;
                        "
                      >
                        -
                      </span>
                      <el-form-item
                        label-width="0"
                        prop="maxAge"
                        style="
                          width: 46%;
                          display: inline-block;
                          margin-bottom: 0;
                        "
                      >
                        <el-input
                          @focus="isShowAge = true"
                          @blur="blurAge($event)"
                          class="appendInput"
                          v-model.trim.number="searchInfo.maxAge"
                          @keyup.enter.native="
                            submitForm('年龄', searchInfo.maxAge, 'enter')
                          "
                          ><span slot="suffix">岁</span></el-input
                        >
                      </el-form-item>
                      <el-button
                        v-show="isShowAge"
                        style="
                          padding: 9px;
                          background: #ff3f3f;
                          color: white;
                          position: absolute;
                          right: -40px;
                          top: 0px;
                        "
                        icon="el-icon-search"
                        @click="submitForm('年龄', searchInfo.maxAge)"
                      ></el-button>
                    </el-form-item>
                  </el-col>
                </el-col>

                <el-collapse-transition>
                  <div v-if="isMoreItemShow">
                    <el-col
                      class="transition-box"
                      style="
                        padding: 15px 0 0;
                        border-top: 1px solid #ededed;
                        width: calc(100% - 24px);
                      "
                    >
                      <el-col
                        :span="24"
                        style="padding-left: 0; padding-right: 0"
                      >
                        <el-col :span="8">
                          <el-form-item
                            label="候选人类型："
                            prop="resumeType"
                            style="min-width: 280px"
                          >
                            <el-radio
                              class="radioBtn"
                              @click.native.prevent="radioClick(1)"
                              style="margin-right: 10px"
                              v-model="searchInfo.resumeType"
                              :label="1"
                              >候选人</el-radio
                            >
                            <el-radio
                              class="radioBtn"
                              @click.native.prevent="radioClick(2)"
                              v-model="searchInfo.resumeType"
                              :label="2"
                              >coldcall</el-radio
                            >
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="性别："
                            prop="sex"
                            style="min-width: 280px"
                          >
                            <el-radio
                              class="radioBtn"
                              @click.native.prevent="radioClick('男')"
                              style="margin-right: 38px"
                              v-model="searchInfo.sex"
                              label="男"
                            ></el-radio>
                            <el-radio
                              class="radioBtn"
                              @click.native.prevent="radioClick('女')"
                              v-model="searchInfo.sex"
                              label="女"
                            ></el-radio>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="第一学历："
                            prop="firstDegrees"
                            style="min-width: 280px"
                          >
                            <el-select
                              multiple
                              collapse-tags
                              clearable
                              v-model="searchInfo.firstDegrees"
                              style="width: 100%"
                              @change="
                                submitForm('第一学历', searchInfo.firstDegrees)
                              "
                            >
                              <el-option label="初中" value="初中"></el-option>
                              <el-option label="高中" value="高中"></el-option>
                              <el-option label="中技" value="中技"></el-option>
                              <el-option label="中专" value="中专"></el-option>
                              <el-option label="大专" value="大专"></el-option>
                              <el-option label="本科" value="本科"></el-option>
                              <el-option label="硕士" value="硕士"></el-option>
                              <el-option label="博士" value="博士"></el-option>
                              <el-option
                                label="博士后"
                                value="博士后"
                              ></el-option>
                              <el-option label="MBA" value="MBA"></el-option>
                              <el-option label="其他" value="其他"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="求职状态："
                            prop="jobStatus"
                            style="min-width: 280px"
                          >
                            <el-select
                              multiple
                              collapse-tags
                              clearable
                              v-model="searchInfo.jobStatus"
                              @change="
                                submitForm('求职状态', searchInfo.jobStatus)
                              "
                              style="width: 100%"
                            >
                              <el-option
                                label="在职看机会"
                                value="在职看机会"
                              ></el-option>
                              <el-option
                                label="在职不看机会"
                                value="在职不看机会"
                              ></el-option>
                              <el-option label="离职" value="离职"></el-option>
                              <el-option
                                label="应届毕业生"
                                value="应届毕业生"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="期望行业："
                            prop="forwardVocations"
                            style="min-width: 280px"
                          >
                            <el-cascader
                              :key="isResouceShow"
                              clearable
                              size="mini"
                              filterable
                              :show-all-levels="false"
                              :options="ColumnInList"
                              collapse-tags
                              @change="
                                submitForm(
                                  '期望行业',
                                  searchInfo.forwardVocations
                                )
                              "
                              v-model="searchInfo.forwardVocations"
                              :props="props"
                              style="width: 100%; height: 34px"
                            ></el-cascader>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="期望城市："
                            prop="forwardLocations"
                            style="min-width: 280px"
                          >
                            <el-cascader
                              :key="isResouceShow"
                              filterable
                              clearable
                              size="mini"
                              :show-all-levels="false"
                              @change="
                                submitForm(
                                  '期望城市',
                                  searchInfo.forwardLocations
                                )
                              "
                              style="width: 100%; height: 34px"
                              :options="ColumnCityList"
                              collapse-tags
                              v-model="searchInfo.forwardLocations"
                              :props="cityProps"
                            ></el-cascader>
                          </el-form-item>
                        </el-col>

                        <el-col :span="8" class="labelAppend1">
                          <el-form-item
                            label="期望职位："
                            prop="forwardTitle"
                            style="min-width: 280px"
                          >
                            <el-input
                              v-model.trim="searchInfo.forwardTitle"
                              @focus="isShowExpectPosition = true"
                              @blur="blurExpectPosition($event)"
                              @keydown.enter.native="
                                submitForm(
                                  '期望职位',
                                  searchInfo.forwardTitle,
                                  'enter'
                                )
                              "
                              placeholder="支持模糊搜索"
                            >
                              <div
                                v-show="isShowExpectPosition"
                                class="changeButtonStyle"
                                slot="append"
                              >
                                <el-button
                                  style="
                                    border-radius: 0;
                                    padding: 9px;
                                    background: #ff3f3f;
                                    color: white;
                                  "
                                  icon="el-icon-search"
                                  @click="
                                    submitForm(
                                      '期望职位',
                                      searchInfo.forwardTitle,
                                      'enter'
                                    )
                                  "
                                ></el-button>
                              </div>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8" style="height: 50px">
                          <el-form-item
                            label="期望月薪："
                            class="delFocus"
                            style="min-width: 280px"
                          >
                            <el-form-item
                              label-width="0"
                              prop="lowAimSalary"
                              style="
                                width: 47%;
                                display: inline-block;
                                margin-bottom: 0;
                              "
                            >
                              <el-input
                                class="appendInput"
                                v-model.number.trim="searchInfo.lowAimSalary"
                                @change="submitForm()"
                                @keydown.enter.native="
                                  submitForm(
                                    '期望月薪',
                                    searchInfo.lowAimSalary,
                                    'enter'
                                  )
                                "
                                ><span slot="suffix" class="appendInput"
                                  >元</span
                                ></el-input
                              >
                            </el-form-item>
                            <span
                              style="
                                display: inline-block;
                                height: 35px;
                                line-height: 35px;
                                width: 6%;
                                text-align: center;
                              "
                            >
                              -
                            </span>
                            <el-form-item
                              label-width="0"
                              prop="highAimSalary"
                              style="
                                width: 47%;
                                display: inline-block;
                                margin-bottom: 0;
                              "
                            >
                              <el-input
                                class="appendInput"
                                v-model.number.trim="searchInfo.highAimSalary"
                                @change="submitForm()"
                                @keydown.enter.native="
                                  submitForm(
                                    '期望月薪',
                                    searchInfo.highAimSalary,
                                    'enter'
                                  )
                                "
                                ><span slot="suffix">元</span></el-input
                              >
                            </el-form-item>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8" class="labelAppend1">
                          <el-form-item
                            label="简历编号："
                            prop="id"
                            style="min-width: 280px"
                          >
                            <el-input
                              v-model.trim="searchInfo.id"
                              @focus="isShowId = true"
                              @blur="blurId($event)"
                              @keydown.enter.native="
                                submitForm('简历编号', searchInfo.id, 'enter')
                              "
                            >
                              <div
                                v-show="isShowId"
                                class="changeButtonStyle"
                                slot="append"
                              >
                                <el-button
                                  style="
                                    border-radius: 0;
                                    padding: 9px;
                                    background: #ff3f3f;
                                    color: white;
                                  "
                                  icon="el-icon-search"
                                  @click="
                                    submitForm(
                                      '简历编号',
                                      searchInfo.id,
                                      'enter'
                                    )
                                  "
                                ></el-button>
                              </div>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8" style="height: 50px">
                          <el-form-item
                            label="人才拥有者："
                            prop="ownerUserIds"
                          >
                            <el-select
                              filterable
                              style="width: 100%"
                              clearable
                              v-model="searchInfo.ownerUserIds"
                              placeholder="请选择"
                              multiple
                              size="mini"
                              collapse-tags
                              @change="
                                submitForm(
                                  '人才拥有者',
                                  searchInfo.ownerUserIds
                                )
                              "
                              popper-class="selectManager"
                            >
                              <el-option
                                v-for="i in searchUserList"
                                :key="i.id"
                                :label="i.name"
                                :value="i.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="渠道："
                            prop="channels"
                            style="min-width: 280px"
                          >
                            <el-select
                              multiple
                              collapse-tags
                              clearable
                              v-model="searchInfo.channels"
                              @change="submitForm('渠道', searchInfo.channels)"
                              style="width: 100%"
                            >
                              <el-option
                                v-for="(i, index) in channelList"
                                :key="'cha' + index"
                                :label="i.value"
                                :value="i.value"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="候选人标签："
                            prop="remark"
                            style="min-width: 280px"
                          >
                            <el-select
                              filterable
                              clearable
                              multiple
                              collapse-tags
                              @change="
                                submitForm('候选人标签', searchInfo.tagIds)
                              "
                              v-model="searchInfo.tagIds"
                              style="width: 100%"
                            >
                              <el-option
                                v-for="(i, index) in TagList"
                                :key="i.id"
                                :label="i.name"
                                :value="i.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="人才创建时间：" prop="remark">
                            <el-date-picker
                              style="width: 100%"
                              v-model="timeVal"
                              value-format="yyyy-MM-dd"
                              @change="getTime"
                              type="daterange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                            >
                            </el-date-picker>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="人才更新时间："
                            prop="lastModifiedTime"
                          >
                            <el-date-picker
                              style="width: 100%"
                              v-model="lastModifiedTime"
                              value-format="yyyy-MM-dd"
                              @change="getlastModifiedTime"
                              type="daterange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                            >
                            </el-date-picker>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="备注周期："
                            prop="commentPeriodEnum"
                            style="min-width: 280px"
                          >
                            <el-select
                              filterable
                              clearable
                              collapse-tags
                              @change="
                                submitForm(
                                  '备注周期',
                                  searchInfo.commentPeriodEnum
                                )
                              "
                              v-model="searchInfo.commentPeriodEnum"
                              style="width: 100%"
                            >
                              <el-option
                                v-for="(i, index) in remarkCycleList"
                                :key="index"
                                :label="i.name"
                                :value="i.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8" class="labelAppend1">
                          <el-form-item
                            label="专业："
                            prop="speciality"
                            style="min-width: 280px"
                          >
                            <el-input
                              v-model.trim="searchInfo.speciality"
                              @focus="isShowSpeciality = true"
                              @blur="blurExpectPosition($event)"
                              @keydown.enter.native="
                                submitForm(
                                  '专业',
                                  searchInfo.speciality,
                                  'enter'
                                )
                              "
                              placeholder="支持模糊搜索"
                            >
                              <div
                                v-show="isShowSpeciality"
                                class="changeButtonStyle"
                                slot="append"
                              >
                                <el-button
                                  style="
                                    border-radius: 0;
                                    padding: 9px;
                                    background: #ff3f3f;
                                    color: white;
                                  "
                                  icon="el-icon-search"
                                  @click="
                                    submitForm(
                                      '专业',
                                      searchInfo.speciality,
                                      'enter'
                                    )
                                  "
                                ></el-button>
                              </div>
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-col>
                    </el-col>
                  </div>
                </el-collapse-transition>
              </el-form>
            </el-col>
          </el-row>
        </el-form>
        <div class="box-title" style="border-top: 0; border: none">
          <el-row :gutter="24">
            <el-col :span="24" class="table-top" style="padding-left: 27px">
              <el-checkbox
                :indeterminate="isIndeterminate"
                class="selectAllWrapper"
                style="margin-right: 10px"
                v-model="allChecked"
                @change="isAllSelected"
                >全选</el-checkbox
              >
              <el-button size="mini" @click="addNew">
                <i class="el-icon-plus"></i> 新增人才
              </el-button>
              <el-dropdown
                class="mgl10"
                trigger="click"
                @command="checkContral($event)"
              >
                <el-button
                  type="default"
                  plain
                  size="mini"
                  :disabled="!mSelection || mSelection.length == 0"
                >
                  批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1" @click.native="addPro"
                    >加入项目</el-dropdown-item
                  >
                  <el-dropdown-item :command="2" @click.native="addToFavorite"
                    >加入文件夹</el-dropdown-item
                  >
                  <el-dropdown-item :command="3" @click.native="addTag"
                    >打标签</el-dropdown-item
                  >
                  <el-dropdown-item :command="4" @click.native="setEmail"
                    >发人才激活邮件</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                type="default"
                plain
                v-if="isShowDelBtn && mSelection.length > 0"
                size="mini"
                @click="removeFavorite"
                >从文件夹移出</el-button
              >
              <el-pagination
                style="float: right"
                ref="pageNationOne"
                :hide-on-single-page="pagination.total > 10 ? false : true"
                v-if="pagination.total > 0 && !listLoading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="pagination.pageSize"
                layout="total, prev, pager, next, sizes, jumper"
                :total="pagination.total"
              ></el-pagination>
            </el-col>
            <div class="clearfix" v-loading="listLoading">
              <template v-if="ListData.length">
                <el-col
                  :span="24"
                  v-for="(i, idx) in ListData"
                  class="resumeCard"
                  :key="String(idx) + i.id"
                >
                  <div>
                    <el-card
                      shadow="hover"
                      class="shadowCard"
                      :class="{ isRead: i.viewed }"
                      style="border-left: 0; border-right: 0"
                    >
                      <div
                        v-if="i.viewed"
                        id="triangle-topleft"
                        class="bodTop"
                        style="display: block; z-index: 99"
                        title="近3天查看过ta的简历"
                      ></div>
                      <el-checkbox
                        v-if="
                          !i.secure ||
                          hasMenuPage('resume:resumeList:seeSecure') ||
                          i.canViewSecure
                        "
                        :value="i.isSelect"
                        @change="isClickSelected(i, idx)"
                        style="z-index: 100; padding: 15px 0 0 0"
                      >
                      </el-checkbox>
                      <div style="width: calc(100% - 22px)">
                        <div class="personFoo" @click="goResumeFoo(i, idx)">
                          <div
                            style="display: inline-block; cursor: pointer"
                            class="cardHover"
                          >
                            <p
                              style="
                                display: inline-block;
                                margin-top: 0;
                                font-size: 16px;
                                font-weight: bold;
                              "
                              class="resumeName tabHref"
                              @mouseenter="showDetail(i, idx)"
                              @mouseleave="mouseoutWork && clearShowDetail()"
                            >
                              <a
                                class="tabHref"
                                :href="
                                  '/resume/detailPage?resumeId=' +
                                  i.id +
                                  '&entry=列表右键新窗口'
                                "
                                @click.prevent="test"
                                v-html="i.name"
                              ></a>
                              <span v-if="i.name_pinyin">(</span
                              >{{ i.name_pinyin
                              }}<span v-if="i.name_pinyin">)</span>
                            </p>
                            <span>
                              <el-tooltip
                                v-if="i.recommendTimes > 0"
                                class="item"
                                effect="dark"
                                :content="
                                  '候选人被推荐了' + i.recommendTimes + '次'
                                "
                                placement="top-start"
                              >
                                <span
                                  v-if="i.recommendTimes > 0"
                                  class="badgeIcon"
                                  style="background: #4690ff"
                                  ><span class="num">{{
                                    i.recommendTimes
                                  }}</span></span
                                >
                              </el-tooltip>
                              <el-tooltip
                                v-if="i.isLocked"
                                class="item"
                                effect="dark"
                                :content="
                                  '候选人在【' +
                                  i.lockProjectName +
                                  '】项目里已锁定'
                                "
                                placement="top-start"
                              >
                                <i
                                  v-if="i.isLocked"
                                  class="badgeIcon iconfont icon-yisuoding"
                                ></i>
                              </el-tooltip>
                              <el-tooltip
                                v-if="i.contactStatus == '联系中'"
                                class="item"
                                effect="dark"
                                :content="
                                  i.contactRemark
                                    ? i.contactRemark + '正在联系中'
                                    : '正在联系中'
                                "
                                placement="top-start"
                              >
                                <i
                                  v-if="i.contactStatus == '联系中'"
                                  class="
                                    badgeIcon
                                    iconfont
                                    icon-zhengzailianxizhong
                                  "
                                ></i>
                              </el-tooltip>
                              <el-tooltip
                                v-if="i.secure"
                                class="item"
                                effect="dark"
                                content="人才保密中"
                                placement="top-start"
                              >
                                <i
                                  v-if="i.secure"
                                  class="badgeIcon iconfont icon-mi"
                                  style="color: #da3737"
                                ></i>
                              </el-tooltip>
                              <el-tooltip
                                v-if="i.channel"
                                class="item"
                                effect="dark"
                                :content="'来源：' + i.channel"
                                placement="top-start"
                              >
                                <i
                                  v-if="i.channel"
                                  class="badgeIcon iconfont"
                                  :class="{
                                    'icon-liepin':
                                      i.channel == '猎聘猎头端' ||
                                      i.channel == '猎聘HR端',
                                    'icon-luru': i.channel == '录入',
                                    'icon-shangchuan':
                                      i.channel == '上传List简历' ||
                                      i.channel == '上传',
                                    'icon-lingying':
                                      i.channel == '领英个人版' ||
                                      i.channel == '领英',
                                    'icon-lagou': i.channel == '拉勾',
                                    'icon-BOSSzhipin': i.channel == 'BOSS直聘',
                                    'icon-qianchengwuyou':
                                      i.channel == '前程无忧',
                                    'icon-zhilian':
                                      i.channel == '智联' ||
                                      i.channel == '卓聘',
                                    'icon-wuyoujingying':
                                      i.channel == '无忧精英',
                                    'icon-maimai': i.channel == '脉脉',
                                    'icon-qi':
                                      i.channel == '插件' ||
                                      i.channel == '合并' ||
                                      i.channel == '58同城',
                                  }"
                                ></i>
                              </el-tooltip>
                            </span>
                            <span class="fontColor1 garyColor" v-if="i.sex">
                              {{ i.sex }}</span
                            >
                            <span class="fontColor1 garyColor" v-if="i.age > 0">
                              . {{ i.age }}岁</span
                            >
                            <span class="fontColor1 garyColor" v-if="i.married">
                              . {{ i.married }}</span
                            >
                            <span
                              class="fontColor1 garyColor"
                              v-if="i.experience"
                            >
                              . {{ i.experience }}年工作经验</span
                            >
                            <template v-if="i.nowLocationName">
                              .
                              <span
                                class="fontColor1 garyColor"
                                v-html="i.nowLocationName"
                              ></span>
                            </template>
                          </div>
                          <span class="fontColor1 fr">
                            <span style="font-size: 12px; padding-right: 5px"
                              >{{ i.ownerName }}
                            </span>
                            <span
                              v-if="i.createdTime"
                              style="
                                padding-right: 5px;
                                border-right: 1px solid #ededed;
                                font-size: 12px;
                              "
                            >
                              {{ i.createdTime }}创建
                            </span>
                            <span
                              v-if="i.lastModifiedTime"
                              style="padding-left: 5px; font-size: 12px"
                            >
                              {{ i.lastModifiedTime }}更新</span
                            >
                          </span>
                        </div>
                        <div
                          class="fontColor1 garyColor clearfix"
                          :class="
                            i.employmentsList.length == 0
                              ? 'contactInformation'
                              : 'contactInformationBoreder'
                          "
                          v-if="
                            (
                              i.mobile +
                              i.email +
                              i.wechatOrOther +
                              i.virtual_phone
                            ).length > 0
                          "
                          style="padding: 10px 0 0; background: none"
                        >
                          <div class="fl">
                            <span style="margin-right: 25px" v-show="i.email">
                              <i
                                style="font-size: 12px; margin-right: 6px"
                                class="
                                  iconfont
                                  fontColor1
                                  icon-rc_lb_yx
                                  garyColor
                                "
                              ></i>
                              <span v-html="i.isShow ? i.email : '***********'">
                                {{ i.isShow ? i.email : "***********" }}</span
                              >
                            </span>
                            <span
                              style="margin-right: 25px"
                              v-show="i.wechatOrOther"
                            >
                              <i
                                style="font-size: 12px; margin-right: 6px"
                                class="
                                  iconfont
                                  fontColor1
                                  icon-rc_lb_wx
                                  garyColor
                                "
                              ></i>
                              <span
                                v-html="
                                  i.isShow ? i.wechatOrOther : '***********'
                                "
                                >{{
                                  i.isShow ? i.wechatOrOther : "***********"
                                }}</span
                              >
                            </span>
                            <span
                              style="margin-right: 25px"
                              v-show="i.mobile || i.virtual_phone"
                            >
                              <i
                                v-if="i.mobile || i.virtual_phone"
                                style="margin-right: 6px"
                                class="
                                  iconfont
                                  fontColor1
                                  icon-rc_lb_sj
                                  garyColor
                                "
                                @click="call(i.mobile)"
                              ></i>
                              <span
                                v-html="
                                  i.isShow
                                    ? i.mobile
                                      ? i.mobile
                                      : i.virtual_phone
                                    : '***********'
                                "
                              ></span>
                              <el-tag
                                size="mini"
                                v-if="
                                  i.mobile && i.virtual_phone
                                    ? false
                                    : i.virtual_phone
                                    ? true
                                    : false
                                "
                                >虚拟号</el-tag
                              >
                              <el-tag
                                class="button1"
                                size="mini"
                                v-if="i.mobileStatus !== null ? true : false"
                                :style="
                                  i.mobileStatus == '正常'
                                    ? 'background:#5189ff;'
                                    : 'background:#333;'
                                "
                                style="
                                  color: #fff;
                                  border: 0;
                                  height: 15px;
                                  line-height: 17px;
                                  padding: 0 3px;
                                "
                                >{{ i.mobileStatus }}</el-tag
                              >
                            </span>
                          </div>

                          <p
                            class="fontColor1 fr iconHover"
                            style="font-size: 12px; float: right"
                            @click="hideContact(i, idx)"
                          >
                            <i
                              class="iconfont fontColor1"
                              :class="
                                i.isShow ? 'icon-rc_eye2' : 'icon-rc_eye1'
                              "
                            ></i>
                            <span style="margin-left: 8px">{{
                              i.isShow ? "隐藏" : "显示"
                            }}</span>
                          </p>
                        </div>
                        <!--              工作情况-->
                        <el-col
                          class="workStatus clearfix"
                          v-if="
                            i.employmentsList.length > 0 ||
                            i.educationsList.length > 0
                          "
                        >
                          <div @click="goResumeFoo(i, idx)">
                            <el-col :span="12" style="padding-left: 0">
                              <el-col
                                :span="24"
                                v-for="j in i.employmentsList"
                                :key="j.id"
                                style="margin-bottom: 2px; padding: 0"
                                v-if="j.company"
                              >
                                <div>
                                  <span
                                    @mouseover="postion(j)"
                                    :title="postionTitle"
                                    class="infoContent"
                                  >
                                    <fixed-length-span
                                      class="linkButton"
                                      style="
                                        color: #898989 !important;
                                        font-size: 13px;
                                      "
                                      :width="160"
                                      :text="j.company"
                                    ></fixed-length-span>
                                    <template v-if="j.title">
                                      <fixed-length-span
                                        style="
                                          color: #898989 !important;
                                          font-size: 13px;
                                        "
                                        :text="'·' + j.title"
                                        :width="130"
                                      ></fixed-length-span>
                                    </template>
                                  </span>
                                  <span
                                    class="fontColor1 garyColor1"
                                    style="
                                      margin-left: 20px;
                                      width: 170px;
                                      font-size: 13px;
                                      line-height: 21px;
                                      color: #898989;
                                    "
                                    v-if="j.startDate || j.endDate || j.isSoFar"
                                    >{{ j.startDate }} 至
                                    {{ j.isSoFar ? "至今" : j.endDate }}</span
                                  >
                                </div>
                              </el-col>
                            </el-col>
                            <el-col :span="12" style="padding-left: 0">
                              <el-col
                                :span="24"
                                v-for="m in i.educationsList"
                                :key="m.id"
                                style="margin-bottom: 2px"
                              >
                                <div v-if="m.school">
                                  <span
                                    :title="
                                      m.school +
                                      (m.education ? ' · ' + m.education : '') + 
                                      (m.speciality ? ' · ' + m.speciality : '')
                                    "
                                    class="text-overflows infoContent"
                                  >
                                    <fixed-length-span
                                      class="linkButton"
                                      style="
                                        color: #898989 !important;
                                        font-size: 13px;
                                      "
                                      :width="200"
                                      :text="m.school"
                                    ></fixed-length-span>
                                    <span
                                      v-if="m.education"
                                      style="
                                        color: #898989 !important;
                                        font-size: 13px;
                                      "
                                    >
                                      · {{ m.education }}</span
                                    >
                                    <span
                                      v-if="m.speciality"
                                      style="
                                        color: #898989 !important;
                                        font-size: 13px;
                                      "
                                    >
                                      · {{ m.speciality }}</span
                                    >
                                  </span>
                                  <span
                                    class="fontColor1"
                                    style="
                                      margin-left: 20px;
                                      width: 170px;
                                      font-size: 13px;
                                      line-height: 21px;
                                      color: #898989;
                                    "
                                    v-if="m.startDate || m.endDate || m.isSoFar"
                                    >{{ m.startDate }} 至
                                    {{ m.isSoFar ? "至今" : m.endDate }}</span
                                  >
                                </div>
                              </el-col>
                            </el-col>
                          </div>
                        </el-col>
                        <div style="clear: both">
                          <span
                            class="tag"
                            v-for="(x, index) in i.labels"
                            :key="index"
                            :style="
                              x.bordered
                                ? { 'border-color': x.color, color: x.color }
                                : {}
                            "
                            style="margin-bottom: 5px"
                            v-html="x.name"
                            >{{ x.name }}</span
                          >
                          <span
                            class="tag"
                            v-for="(x, index) in i.customLabels"
                            :key="index"
                            style="
                              background: #ff5b5b;
                              color: #fff;
                              margin-bottom: 5px;
                              border: 0;
                            "
                            v-html="x.name"
                            >{{ x.name }}</span
                          >
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
              </template>
              <p v-else style="text-align: center; color: #6f6a6a">
                暂无数据……
              </p>
            </div>
            <div style="margin: 10px 0px 20px" class="clearfix">
              <el-col :span="24"
                ><el-pagination
                  ref="pageNationOne"
                  :hide-on-single-page="pagination.total > 10 ? false : true"
                  v-if="pagination.total > 0 && !listLoading"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pagination.pageNo"
                  :page-sizes="[10, 20, 50, 100]"
                  :pager-count="5"
                  :page-size="pagination.pageSize"
                  layout="total, prev, pager, next, sizes, jumper"
                  :total="pagination.total"
                ></el-pagination
              ></el-col>
            </div>
          </el-row>
        </div>
      </section>
    </el-row>
    <!-- 订阅搜索 -->
    <el-dialog
      :visible.sync="subscribeSearchDialog"
      :modal-append-to-body="false"
      append-to-body
      title="订阅搜索"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        ref="subscribeForm"
        @submit.native.prevent
        :model="subscribeForm"
        :rules="subscribeRules"
      >
        <el-form-item prop="name">
          <el-input
            v-model.trim="subscribeForm.name"
            maxlength="12"
            placeholder="请给订阅搜索取个名字"
            @keyup.enter.native="saveSubscribe('subscribeForm')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="subscribeSearchDialog = false"
          >取消</el-button
        >
        <el-button
          size="mini"
          :loading="isSubscribeSearch"
          @click="saveSubscribe('subscribeForm')"
          type="primary"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--    文件夹管理-->
    <el-dialog
      :visible.sync="fileManagement"
      :modal-append-to-body="false"
      append-to-body
      title="文件夹管理"
      @close="updateFavoriteList"
      :close-on-click-modal="false"
      width="720px"
    >
      <file
        v-if="fileManagement"
        @getFavoriteData="childByValue"
        @length="getLength"
        @delFile="delFile"
        :type="type"
        :checkbox="isCheckbox"
      ></file>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="isCheckbox.edit && isShowSaveBtn"
      >
        <el-button
          :disabled="favoriteList.favoriteIds.length == 0"
          size="mini"
          :loading="fileManagementloading"
          @click="saveFavorite"
          type="primary"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 个性化标签 -->
    <el-dialog
      :visible.sync="personalizedTagsDialog"
      :modal-append-to-body="false"
      append-to-body
      title="编辑个性化标签"
      :close-on-click-modal="false"
      width="600px"
    >
      <personalized-tags
        v-if="personalizedTagsDialog"
        ref="personalizedTags"
      ></personalized-tags>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="(personalizedTagsDialog = false), getPersonalizedTags()"
          >完成</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="加入项目"
      :visible.sync="dialogaddProgreamVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="80%"
    >
      <progream
        :entry="projectEntry"
        v-if="dialogaddProgreamVisible"
        :resumeIds="this.resumeIds"
        :hookedLabelIds="searchInfo.tagIds"
        @loadList="clearPrograme()"
      ></progream>
    </el-dialog>
    <el-drawer
      :visible.sync="dialogResumeVisible"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">{{ titleName }}</div>
      <div class="drawerCon">
        <resumeInfo
          ref="saveResumeInfo"
          v-if="dialogResumeVisible"
          @loadList="loadList()"
          entry="人才"
        ></resumeInfo>
        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(dialogResumeVisible = false), clearStatus()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-dialog
      title="标签管理"
      :visible.sync="dialogVisibleOfTag"
      append-to-body
      :close-on-click-modal="false"
      width="960px"
      custom-class="addTagDig tagsDigContent"
      destroy-on-close
    >
      <label-management
        ref="labelManagement"
        v-if="dialogVisibleOfTag"
        :resumeId="favoriteList.itemIds"
        entry="人才库-人才列表"
        @loadList="(dialogVisibleOfTag = false), loadList()"
      ></label-management>
      <span slot="footer" class="dialog-footer" v-if="dialogVisibleOfTag">
        <el-button
          type="default"
          plain
          size="mini"
          @click="dialogVisibleOfTag = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveTags"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <el-drawer
      :visible.sync="isShowDetail"
      append-to-body
      :modal="false"
      custom-class="drawerClass redrawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div
        slot="title"
        class="drawerTitle"
        style="display: flex; justify-content: space-between"
      >
        <p><i class="drawIcon iconfont icon-rc_bt"></i>人才</p>
        <div>
          <el-button size="mini" @click="goNewPage(resumeId)">
            新窗口打开
          </el-button>
          <el-button size="mini" @click="showleftContent(resumeId)">
            {{ this.isshowleftContent ? "隐藏" : "显示" }}侧边栏
          </el-button>
        </div>
      </div>
      <div class="l_box" style="display: flex">
        <div class="l_content" v-if="isshowleftContent">
          <p class="l_c_tit">姓名</p>
          <div class="l_c_list">
            <p
              class="tabHref"
              :class="i.isChoice ? 'l_c_list_active' : ''"
              v-for="(i, idx) in ListData1"
              :key="String(idx) + i.id"
              @click="updata(i.id)"
            >
              {{ i.name }}
            </p>
          </div>
        </div>
        <resumeDetails
          ref="resumeDetails"
          :style="
            isshowleftContent ? 'width: calc(100% - 210px);' : 'width:100%'
          "
          style="height: calc(100vh - 120px); overflow: auto"
          v-if="isShowDetail"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          module="FULLTEXT_INDEX"
          :searchKey="searchKeyVal"
          :operateId="operateData.operateId"
          :repeatResume="repeatResume"
          :detailShowEntry="detailShowEntry"
          :searchInfo="detailSearchInfo"
          :searchObj="searchInfo"
          :isShowRemark='isShowRemark'
          @getCallMobileInfo="getCallMobileInfo"
        ></resumeDetails>
      </div>
    </el-drawer>
    <el-dialog
      class="dialogFloatwindow"
      :visible.sync="mouseoverDialog"
      append-to-body
      :close-on-click-modal="true"
      :modal="false"
      custom-class="floatwindow"
      :before-close="resumeDigClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>人才
      </div>
      <resumeDetails
        v-if="mouseoverDialog"
        :resumeDetailId="resumeId"
        :hover="hoverInfo"
        :searchKey="searchKeyVal"
        :repeatResume="repeatResume"
        detailShowEntry="列表悬浮"
        module="FULLTEXT_INDEX"
        :searchInfo="detailSearchInfo"
        :searchObj="searchInfo"
        @getCallMobileInfo="getCallMobileInfo"
      ></resumeDetails>
    </el-dialog>
    <!-- 发人才激活邮件 -->
    <el-drawer
      :visible.sync="emailDrawer"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-youjian"></i>发人才激活邮件
      </div>
      <div class="drawerCon">
        <email ref="email" v-if="emailDrawer" :mSelection="mSelection"></email>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import resumeDetails from "../../components/resumeDetail";
import labelManagement from "../../components/resume/labelManagement";
import resumeInfo from "../../components/resumeInfo";
import file from "../../components/file/file";
import * as data from "./../../libs/data.json";

import {
  getListFavorite, //收藏夹列表
  addToFavorite,
  removeFromFavorite,
  selectAdvancePageList, //获取人才列表
  getUserBaseInfos, //获取用户列表
  getLabelOptions, //获取标签列表
  getCommonColumnList, //获取行业 城市列表
  SearchAddOrEdit,
  GetSearchPage,
  deleteSearchById,
  GetSearchById, //给候选人打标签
  cityTree, //城市树
  resumeDataCount, //快捷菜单人才数量
  labelCustomTagged, //当前用户所有已设置的个性标签
} from "../../api/api";
import {
  statisticsFulltextIndex, //点击全文搜索
  statisticsNetworkWide, //点击全网搜
  statisticsResumeIndex, //记录全文搜索详情
  statisticsResumeSearchDetailDuration, //人才搜索-全文检索人才详情页停留时间
  statisticsResumeIndexPrecision, //记录人才精确搜索；用户仅使用精确搜索模块中的内容进行检索，检索一次上报一次
  statisticsResumeNewWindow, //记录人才详情，新窗口打开点击量
  statisticsResumeAddProject, //记录加入项目点击量
  statisticsResumeOperationOperate, //人才库-路径操作
  statisticsClickFunctionEscape, //记录功能或页面跳出
  statisticsFrameClick,
} from "../../api/buryingPoint";
import DB from "../../libs/db";
import { mapGetters } from "vuex";
import progream from "../../components/progream";
import FixedLengthSpan from "../../components/common/FixedLengthSpan";
import CityChoose from "../../components/cityChoose";
import personalizedTags from "../../components/personalizedTags";
import email from "../../components/resume/email";
export default {
  components: {
    CityChoose,
    FixedLengthSpan,
    resumeDetails,
    progream,
    resumeInfo,
    file,
    personalizedTags,
    labelManagement, //标签管理
    email, //人才激活邮件
  },
  computed: {
    hasSelect() {
      let hasSelect = false;
      hasSelect = this.ListData.find((item) => {
        return item.isSelect == true;
      });
      return hasSelect;
    },
    buttonStyle() {
      return (val) => {
        let buttonStyle = {};
        this.channelList.forEach((item) => {
          if (val == item.value) {
            buttonStyle = {
              color: `rgba(${item.color}, 1)`,
              backgroundColor: `rgba(${item.color}, .1)`,
              borderColor: `rgba(${item.color}, .2)`,
            };
          }
        });
        return buttonStyle;
      };
    },
    ...mapGetters({
      collapsed: "upDateCollapsed",
      addresumeStatus: "upStatusAddResume",
      addResume: "upDateAddResumeStatus",
      getResumeId: "upDateResumeId",
      getResumePreCloseTime: "upDateResumePreCloseTime",
    }),
  },
  watch: {
    isShowDetail(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    dialogResumeVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    addresumeStatus(newVal, oldVal) {
      if (newVal) {
        this.addNew();
      }
    },
    radioNowLocations(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        // 此时是选中了新项目
        this.searchInfo.nowLocations.push(
          JSON.parse(newVal[newVal.length - 1])
        );
      } else if (newVal.length < oldVal.length) {
        // 此时是取消了选中
        for (var i = 0; i < oldVal.length; i++) {
          let istrue = false;
          newVal.some((item, idx) => item == oldVal[i]);
          if (newVal.some((item, idx) => item == oldVal[i]) == false) {
            this.searchInfo.nowLocations = this.searchInfo.nowLocations.filter(
              (item) => {
                return JSON.stringify(item) != oldVal[i];
              }
            );
            break;
          }
        }
      }
    },
    "$route.query": {
      handler(v, ov) {
        console.log(45525,v)
        //如果从人才页面跳转到订阅页面
        if (v.order) {
          GetSearchById(v.order).then((res) => {
            if (res.success) {
              this.searchInfo = res.result.searchList;
              this.loadList();
            }
          });
        }
        if (v.folder) {
          this.searchInfo = {
            matchAll: true,
            certificate: "", //证书
            channels: [], //渠道
            checkSchoolType: false, //是否按学校类型搜索
            comment: "", //候选人备注
            company: "", //公司名称
            contact: "", //联系方式
            educations: ["UnLimit"], //教育经历 [本科及以上, 硕士及以上, 博士及以上, 大专及以上, 高中及以下]
            english: "", //语言能力
            favoriteIds: [], //收藏夹Id
            firstDegrees: [], //第一学历
            forwardLocations: [], //期望城市
            forwardTitle: "", //期望职位
            forwardVocation: "", //期望职能
            forwardVocations: [], //期望行业
            functionIds: [], //职能
            highAimSalary: "", //期望最多月薪
            id: "", //简历编号
            isAllCompany: false, //是否全部公司
            isAllTitle: false, //是否全部职位
            is_tongzhao: false, //是否统招
            jobStatus: [], //求职状态
            joinProjectName: "", //加入项目名称
            lowAimSalary: "", //期望最少月薪
            major: "", //专业名称
            married: [], //婚姻状况
            maxAge: "", //最大年龄
            minAge: "", //最小年龄
            nowLocations: [], //现居住城市
            ownerUserIds: [], //人才拥有者
            projectName: "", //项目名称
            reasonOfLeaving: "", //离职原因
            tagIds: [], //标签
            resumeType: "", //候选人类型：1-候选人，2-code call
            school: "", //学校名称
            sex: "", //性别
            title: "", //职位名称
            type: "", //列表样式
            userName: "", //姓名
            vocationIds: [], //行业Id
            workExperiences: ["UnLimit"], //工作年限 [UnLimit, OneToThree, ThreeToFive, FiveToTen, TenMore]
          };
          this.searchInfo.quickType = "所有人才";
          this.searchInfo.favoriteId = this.$route.query.folder;
          this.loadList();
        }
        if (v.label) {
          this.searchInfo = {
            matchAll: true,
            certificate: "", //证书
            channels: [], //渠道
            checkSchoolType: false, //是否按学校类型搜索
            comment: "", //候选人备注
            company: "", //公司名称
            contact: "", //联系方式
            educations: ["UnLimit"], //教育经历 [本科及以上, 硕士及以上, 博士及以上, 大专及以上, 高中及以下]
            english: "", //语言能力
            favoriteIds: [], //收藏夹Id
            firstDegrees: [], //第一学历
            forwardLocations: [], //期望城市
            forwardTitle: "", //期望职位
            forwardVocation: "", //期望职能
            forwardVocations: [], //期望行业
            functionIds: [], //职能
            highAimSalary: "", //期望最多月薪
            id: "", //简历编号
            isAllCompany: false, //是否全部公司
            isAllTitle: false, //是否全部职位
            is_tongzhao: false, //是否统招
            jobStatus: [], //求职状态
            joinProjectName: "", //加入项目名称
            lowAimSalary: "", //期望最少月薪
            major: "", //专业名称
            married: [], //婚姻状况
            maxAge: "", //最大年龄
            minAge: "", //最小年龄
            nowLocations: [], //现居住城市
            ownerUserIds: [], //人才拥有者
            projectName: "", //项目名称
            reasonOfLeaving: "", //离职原因
            tagIds: [], //标签
            resumeType: "", //候选人类型：1-候选人，2-code call
            school: "", //学校名称
            sex: "", //性别
            title: "", //职位名称
            type: "", //列表样式
            userName: "", //姓名
            vocationIds: [], //行业Id
            workExperiences: ["UnLimit"], //工作年限 [UnLimit, OneToThree, ThreeToFive, FiveToTen, TenMore]
          };
          this.searchInfo.quickType = "所有人才";
          this.searchInfo.customLabelIds = [this.$route.query.label];
          this.loadList();
        }
        if (v.t) {
          this.loadList();
        }
        if (this.$route.query.resumeId) {
      //显示项目详情
      this.isShowDetail = true;
      this.time = true;
      this.mouseoverDialog = false;
      this.resumeId = this.$route.query.resumeId;
      this.searchKeyVal = this.searchInfo.searchKey;
      this.hoverInfo.matchAll = this.searchInfo.matchAll;
      this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
      this.hoverInfo.hover = true;
    }
        if (this.$route.query.type&&this.$route.query.type=='remark') {
          this.isShowRemark = true;
        }else{
          this.isShowRemark = false;
        }
      },
      deep: true,
    },
    "createTag.labelIds"(nVal, oVal) {
      let list = null;
      list = oVal.concat(nVal).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
      this.addTypeList.forEach((item) => {
        if (item.name == this.choiceTypeName) {
          item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
        } else {
          item.labels.forEach((i) => {
            if (i.id == list[0]) {
              item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
            }
          });
        }
      });
    },
  },
  data() {
    const intNum = (rule, value, callback) => {
      // 校验数字
      let reg = /^[0-9]*$/;
      if (value && !reg.test(value)) {
        callback(new Error("请输入数字"));
      } else {
        callback();
      }
    };
    return {
      actived: "所有人才",
      isShowDetail: false,
      mouseoverDialog: false,
      time: false,
      resumeId: "",
      showFavorBtn: false,
      isShowDelBtn: false,
      isDisableDel: true,
      dialogVisibleOfTag: false,
      titleName: "",
      dialogResumeVisible: false,
      saveloading: false,
      isaddTag: false,
      listLoading: false,
      isShowSaveBtn: false,
      mSelection: "", //选中项
      dialogaddProgreamVisible: false,
      resumeIds: [], //人才id集合
      remarkList: [],
      subscribeSearchDialog: false,
      isShowExpectPosition: false,
      isIndeterminate: false,
      isBodTop: false,
      allChecked: false,
      type: "人才",
      fileManagement: false,
      fileManagementloading: false,
      personalizedTagsDialog: false,
      personalizedTags: {
        name: "",
        list: [],
      },
      isCheckbox: {
        val: true,
        edit: false,
      },
      subscribeRules: {
        name: [
          { required: true, trigger: "blur", message: "请给订阅搜索取个名字" },
        ],
      },
      subscribeForm: {
        name: "",
        searchList: "",
        type: "人才",
        version: "",
      },
      activeNames: ["1", "2", "3", "4"],
      postionTitle: "",
      shareData: [],
      activeNames1: ["1", "2"],
      OrderSearchList: [],
      menuList1: JSON.parse(sessionStorage.getItem("permiss")),
      menuList: [
        {
          name: "我入库的人才",
          val: "我入库的人才",
        },
        {
          name: "我备注的人才",
          val: "我备注的人才",
        },
        {
          name: "所有人才",
          val: "所有人才",
        },
        {
          name: "我的最近浏览",
          val: "我的最近浏览",
        },
        {
          name: "导入记录",
          val: "导入记录",
        },
        {
          name: "待处理人才",
          val: "待处理人才",
        },
      ],
      myFileData: [],
      dialogCusVisible: false,
      radioGroup: "",
      isCreate: true,
      defaultType: 0,
      searchUserList: [],
      isShowName: false,
      searchPanelLoading: false,
      isShowFocus: false,
      isShowSpeciality:false,
      saveSearchVal: [],
      dbClass: new DB({
        dbKey: "resumeList_searchParam",
      }),
      filter: {
        name: "",
      },
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      cityProps: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: true,
      },
      repeatResume: { type: "列表", valId: "" },
      searchKeyVal: "",
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      searchInfo: {
        recordId: "",
        order: "",
        quickType: "所有人才",
        matchAll: true,
        majorSearch: false, //核心字段搜索
        searchKey: "",
        certificate: "", //证书
        channels: [], //渠道
        checkSchoolType: false, //是否按学校类型搜索
        comment: "", //候选人备注
        company: "", //公司名称
        contact: "", //联系方式
        educations: ["UnLimit"], //教育经历 [本科及以上, 硕士及以上, 博士及以上, 大专及以上, 高中及以下]
        english: "", //语言能力
        favoriteId: "", //收藏夹Id
        firstDegrees: [], //第一学历
        forwardLocations: [], //期望城市
        forwardTitle: "", //期望职位
        forwardVocation: "", //期望职能
        forwardVocations: [], //期望行业
        functionIds: [], //职能
        highAimSalary: "", //期望最多月薪
        id: "", //简历编号
        isAllCompany: false, //是否全部公司
        isAllTitle: false, //是否全部职位
        is_tongzhao: false, //是否统招
        jobStatus: [], //求职状态
        joinProjectName: "", //加入项目名称
        lowAimSalary: "", //期望最少月薪
        major: "", //专业名称
        married: [], //婚姻状况
        maxAge: "", //最大年龄
        minAge: "", //最小年龄
        nowLocations: [], //现居住城市
        ownerUserIds: [], //人才拥有者
        projectName: "", //项目名称
        reasonOfLeaving: "", //离职原因
        tagIds: [], //标签
        resumeType: "", //候选人类型：1-候选人，2-code call
        school: "", //学校名称
        sex: "", //性别
        title: "", //职位名称
        type: "", //列表样式
        userName: "", //姓名
        vocationIds: [], //行业Id
        workExperiences: ["UnLimit"], //工作年限 [UnLimit, OneToThree, ThreeToFive, FiveToTen, TenMore]
        menuProperty: "快捷菜单",
        hits: 0,
        refresh: true,
      },
      ListData: [],
      ListData1: [],
      SearchplanList: [],
      isMoreItemShow: false,
      isMoreCity: false,
      isShowCompany: false,
      isShowAge: false,
      cascaderCityKey: 0,
      isResouceShow: 0,
      isShowContact: false,
      rulesOfSearch: {
        id: [{ validator: intNum, trigger: "blur" }],
        minAge: [{ validator: intNum, trigger: "blur" }],
        maxAge: [{ validator: intNum, trigger: "blur" }],
        lowAimSalary: [{ validator: intNum, trigger: "blur" }],
        highAimSalary: [{ validator: intNum, trigger: "blur" }],
      },
      radioNowLocations: [],
      isShowTitle: false,
      userList: [], //用户列表
      TagList: [], //标签列表
      ColumnCityList: [], // 城市列表
      ColumnInList: [], //行业
      ColumnPosList: [], //职能
      mouseoutWork: true,
      isShowId: false,
      channelList: data.selectChannelList,
      bjId: "",
      shId: "",
      gzId: "",
      szId: "",
      hzId: "",
      cdId: "",
      whId: "",
      cqId: "",
      timeVal: "",
      lastModifiedTime: "",
      delFiles: {
        favoriteId: "",
        itemIds: [],
      },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      isSubscribeSearch: false,
      isshowleftContent: false,

      dataCount: [],

      remarkCycleList: [
        { id: "HALF_YEAR", name: "近半年无备注" },
        { id: "ONE_YEAR", name: "近1年无备注" },
        { id: "TWO_YEAR", name: "近2年无备注" },
      ],
      projectEntry: "",
      detailShowEntry: "",
      detailSearchInfo: {},
      operateData: {},
      isOperation: false,
      emailDrawer: false,
      isShowRemark:false
    };
  },
  methods: {
    clearPrograme() {
      this.loadList();
      this.mSelection = "";
      this.dialogaddProgreamVisible = false;
    },
    delFile(id) {
      if (
        this.favoriteList.favoriteIds.length > 0 &&
        this.favoriteList.favoriteIds.indexOf(id) > -1
      ) {
        this.favoriteList.favoriteIds.splice(
          this.favoriteList.favoriteIds.indexOf(id),
          1
        );
      }
    },
    getCallMobileInfo(params) {
      this.isShowDetail = false;
      this.loadList();
    },
    clearShowDetail() {
      if (this.timeOut) {
        clearInterval(this.timeOut);
        this.time = false;
      }
    },
    clickSearch(val) {
      this.searchInfo.searchKey = val;
      this.isShowFocus = false;
    },
    assignment() {
      this.saveSearchVal = this.dbClass.list;
    },
    clearLocal(e) {
      this.saveSearchVal = this.dbClass.clear;
    },
    blurId(e) {
      setTimeout(() => {
        this.isShowId = false;
      }, 500);
    },
    blurExpectPosition(e) {
      setTimeout(() => {
        this.isShowExpectPosition = false;
      }, 500);
    },
    saveInfos() {
      //保存人才信息 调用子组件方法
      this.saveloading = true;
      this.$refs.saveResumeInfo
        .saveResume()
        .then((value) => {
          this.saveloading = false;
          this.dialogResumeVisible = false;
          this.loadList();
          if (this.addresumeStatus) {
            this.$store.dispatch("upStatusAddResume");
          }
        })
        .catch(() => {
          this.saveloading = false;
          if (this.addresumeStatus) {
            this.$store.dispatch("upStatusAddResume");
          }
        });
    },
    reset() {
      this.highsearch = false;
      this.favorite = false;
    },
    handleClose(done) {
      if (this.isShowDetail) {
        this.postStatisticsResumeSearchDetailDuration();
      }
      this.isshowleftContent = false;
      done();
      if (this.addresumeStatus) {
        this.$store.dispatch("upStatusAddResume");
      }
      this.reset();
      if (this.favorite) {
        this.resetForm("createDate");
      }
      this.emailDrawer = false;
    },
    clearStatus() {
      if (this.addresumeStatus) {
        this.$store.dispatch("upStatusAddResume");
      }
    },
    addNew() {
      this.isOperation = true;
      this.$store.dispatch("upDateAddResumeStatus", true);
      this.handleStatisticsResumeOperationOperate("新增人才", "列表");
    },

    delSearch(id) {
      //删除订阅搜索
      this.$confirm("确定删除该订阅?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteSearchById(id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadOrderSearchList();
              if (this.actived == id) {
                this.reset();
                this.loadList();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editFavorite() {
      //编辑收藏夹
      this.fileManagement = true;
      this.isCheckbox.edit = false;
    },
    //编辑个性化标签
    editPersonalizedTags() {
      this.personalizedTagsDialog = true;
    },
    //获取当前用户所有已设置的个性标签
    getPersonalizedTags() {
      labelCustomTagged().then((res) => {
        if (res.success) {
          this.personalizedTags.list = res.result;
        }
      });
    },
    saveFavorite() {
      this.fileManagementloading = true;
      addToFavorite(this.favoriteList).then((res) => {
        this.fileManagement = false;
        this.fileManagementloading = false;
        this.$message.success(res.message);
      });
    },
    getLength(val) {
      if (val) {
        this.isShowSaveBtn = true;
      } else {
        this.isShowSaveBtn = false;
      }
    },
    updateFavoriteList() {
      this.favoriteList = {
        favoriteIds: [],
        itemIds: [],
      };
      this.getListFavorite();
    },
    getListFavorite() {
      getListFavorite({ type: this.type }).then((res) => {
        this.shareData = res.result.shareList;
        this.myFileData = res.result.myList;
      });
    },
    checkContral(e) {
      this.mSelection = this.ListData.filter((i) => i.isSelect);
      let ids =
        this.mSelection && this.mSelection.length > 0
          ? this.mSelection.map((item) => item.id)
          : [];
      switch (e) {
        case 1:
          break;
        case 2: //批量设为保密
          break;
        case 3: //批量取消保密
          break;
      }
    },
    // 跳转到详情
    goResumeFoo(data, index) {
      this.isOperation = true;
      if (
        data.secure &&
        !this.hasMenuPage("resume:resumeList:seeSecure") &&
        !data.canViewSecure
      ) {
        this.$confirm("人才保密中，无法查看详情", "提示", {
          showConfirmButton: false,
          cancelButtonText: "关闭",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      this.detailShowEntry = "列表点击";
      this.isShowDetail = true;
      this.time = true;
      this.mouseoverDialog = false;
      this.resumeId = data.id;
      this.searchKeyVal = this.searchInfo.searchKey;
      this.hoverInfo.matchAll = this.searchInfo.matchAll;
      this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
      this.hoverInfo.hover = true;
      this.openTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      this.detailSearchInfo.ranks =
        (this.pagination.pageNo - 1) * this.pagination.pageSize + (index + 1);
      this.detailSearchInfo.resumeId = data.id;
      this.detailSearchInfo.operatedTime = new Date().Format(
        "yyyy-MM-dd hh:mm:ss"
      );
      this.detailSearchInfo.menuName = this.actived;
      this.detailSearchInfo.first = this.getResumeId == data.id ? false : true;
      this.detailSearchInfo.preCloseTime = this.getResumePreCloseTime;
      this.detailSearchInfo.menuType = this.$route.query.order
        ? "订阅搜索"
        : this.$route.query.folder
        ? "文件夹"
        : this.$route.query.label
        ? "个性化标签"
        : "快捷菜单";
      this.$store.dispatch("upDateResumeId", data.id);
    },
    isClickSelected(row, index) {
      this.handleStatisticsResumeOperationOperate("勾选", "列表");
      this.mouseoverDialog = false;
      this.ListData.splice(index, 1, {
        ...row,
        isSelect: !row.isSelect,
      });
      this.mSelection = this.ListData.filter((i) => i.isSelect);
      this.favoriteList.itemIds = this.mSelection.map((i) => i.id);
      this.isDisableDel = this.mSelection.length ? false : true;
      this.delFiles.itemIds = this.mSelection.map((i) => i.id);
      if (this.ListData.some((item) => item.isSelect)) {
        if (this.ListData.every((item) => item.isSelect)) {
          this.allChecked = true;
          this.isIndeterminate = false;
        } else {
          this.isIndeterminate = true;
          this.allChecked = false;
        }
      } else {
        this.isIndeterminate = false;
        this.allChecked = false;
      }
    },
    // 打标签
    addTag() {
      this.isOperation = true;
      this.handleStatisticsResumeOperationOperate("打标签", "列表");
      const _isSelected = this.ListData.filter((i) => i.isSelect);
      this.multipleSelection = _isSelected;
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择需要打标签的项");
        return false;
      }
      this.dialogVisibleOfTag = true;
      this.searchHoverContentIsShow = false;
      this.searchTag = "";
      this.isaddTag = true;
    },
    loadTagList(type) {
      //标签列表
      getLabelOptions().then((res) => {
        if (res.success) {
          this.TagList = res.result;
        }
      });
    },
    addToFavorite() {
      this.isOperation = true;
      if (this.mSelection && this.mSelection.length > 0) {
        this.fileManagement = true;
        this.isCheckbox.edit = true;
      } else {
        this.$message.error("请先选择公司");
      }
    },
    childByValue(childValue) {
      this.favoriteList.favoriteIds = childValue;
    },
    addPro() {
      this.isOperation = true;
      this.startTimeStamp = Math.round(new Date().getTime() / 1000);
      //加入项目
      const _isSelected = this.ListData.filter((i) => i.isSelect);
      this.multipleSelection = _isSelected;

      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择需要加入的项");
        return false;
      }
      let Protect = [];

      this.resumeIds = []; //可以提交的id
      let ids = [];
      this.multipleSelection.forEach((i) => {
        if (i.isLocked) {
          Protect.push("【" + i.name + "】");
        } else {
          this.resumeIds.push(i.id);
        }
        ids.push(i.id);
      });
      this.projectEntry =
        this.searchInfo.searchKey !== ""
          ? "人才列表（全文检索）"
          : "人才列表（常规搜索）";
      statisticsResumeAddProject({
        resumeIds: ids,
        entry: this.projectEntry,
      }).then();
      if (Protect.length > 0) {
        if (Protect.length == this.multipleSelection.length) {
          this.$alert("操作候选人均触发候选人保护机制，无法加入项目", "提示", {
            confirmButtonText: "确定",
            customClass: "altCls",
            callback: (action) => {
              if (action == "confirm") {
                this.handleStatisticsClickFunctionEscape(
                  "点击了【全部触发保护机制提示弹框】的确定"
                );
              } else {
                this.handleStatisticsClickFunctionEscape(
                  "点击了【全部触发保护机制提示弹框】的X"
                );
              }
            },
          });
          return false;
        } else {
          let ProtectName = Protect.join("");
          this.$confirm(
            '<p style="font-weight:bold">' +
              ProtectName +
              "触发候选人保护机制无法加入项目</p><p>点击【继续】按钮，继续操作其他候选人" +
              "</p>",
            "提示",
            {
              confirmButtonText: "继续",
              showCancelButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            }
          )
            .then(() => {
              this.dialogaddProgreamVisible = true;
            })
            .catch(() => {
              this.handleStatisticsClickFunctionEscape(
                "点击了【部分触发保护机制提示弹框】的X"
              );
            });
        }
      } else {
        this.dialogaddProgreamVisible = true;
      }
    },
    removeFavorite() {
      this.$confirm("确定移出选中项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          this.delFiles.favoriteId = this.$route.query.folder;
          removeFromFavorite(this.delFiles).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.pagination.pageNo = 1;
              this.mSelection = [];
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((i) => ({
        ...i,
        isSelect: true,
      }));
    },
    addFavorite() {
      // 获取已选中的id
      const _isSelected = this.ListData.filter((i) => i.isSelect);
      this.createDate.itemIds = [];
      this.multipleSelection = _isSelected;
      if (_isSelected.length === 0) {
        return this.$message.error("请先选择需要加入的项");
      }
      this.dialogVisible = true;
      this.favorite = true;
      this.highsearch = false;
      this.titName = "加入收藏夹";
      this.createDate.favoriteId = "";
      this.loadFavorList("人才");
    },
    blurContact(e) {
      setTimeout(() => {
        this.isShowContact = false;
      }, 500);
    },
    blurUser(e) {
      setTimeout(() => {
        this.isShowName = false;
      }, 500);
    },
    blurCompany(e) {
      setTimeout(() => {
        this.isShowCompany = false;
      }, 500);
    },
    blurAge(e) {
      setTimeout(() => {
        this.isShowAge = false;
      }, 500);
    },
    handleChangeNowLocations(e) {
      this.isOperation = true;
      this.changArrOfNowLocations();
    },
    //是否全选
    isAllSelected() {
      this.handleStatisticsResumeOperationOperate("勾选", "列表");
      if (this.allChecked) {
        const _id = this.ListData.map((i) => i.id);
        this.ListData = this.ListData.map((i) => ({
          ...i,
          isSelect: true,
        }));
        this.mSelection = this.ListData;
        this.favoriteList.itemIds = this.mSelection.map((i) => i.id);
        this.delFiles.itemIds = this.mSelection.map((i) => i.id);
      } else {
        const _id = this.ListData.map((i) => i.id);
        this.ListData = this.ListData.map((i) => ({
          ...i,
          isSelect: false,
        }));
        this.mSelection = [];
      }

      this.isBodTop = !this.isBodTop;
      this.isIndeterminate = false;
    },
    loadColumnInList(type) {
      //行业列表
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    // 判断用户是否有解锁人才保护权限
    checkMenuList() {
      if (
        this.menuList1.findIndex((item) => {
          return item.authority == "resume:resumeList:lock";
        }) == -1
      ) {
        this.showLock = false;
      } else {
        this.showLock = true;
      }
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.isAllSelected();
      this.pagination.pageNo = val;
      this.loadList(1);
    },
    loadColumnPosList(type) {
      //职能列表
      this.ColumnPosList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnPosList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    loadColumnCityList(type) {
      //城市列表
      this.ColumnCityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;
          ++this.isResouceShow;
          // 获取默认城市北京、上海、广州、深圳、杭州、成都、武汉对应的id
          const defaultCity = [
            "北京",
            "上海",
            "广州",
            "深圳",
            "杭州",
            "成都",
            "武汉",
            "重庆",
          ];
          const defaultCityId = [[], [], [], [], [], [], [], []];
          for (var i = 0; i < defaultCity.length; i++) {
            this.ColumnCityList.forEach((item) => {
              if (item.name == defaultCity[i]) {
                defaultCityId[i].push(item.id);
              } else if (item.children) {
                item.children.forEach((it) => {
                  if (it.name == defaultCity[i]) {
                    defaultCityId[i].push(it.parentId);
                    defaultCityId[i].push(it.id);
                  }
                });
              }
            });
          }
          this.bjId = JSON.stringify(defaultCityId[0]);
          this.shId = JSON.stringify(defaultCityId[1]);
          this.gzId = JSON.stringify(defaultCityId[2]);
          this.szId = JSON.stringify(defaultCityId[3]);
          this.hzId = JSON.stringify(defaultCityId[4]);
          this.cdId = JSON.stringify(defaultCityId[5]);
          this.whId = JSON.stringify(defaultCityId[6]);
          this.cqId = JSON.stringify(defaultCityId[7]);
        }
      });
    },
    educationsChanged(e, type) {
      this.isOperation = true;
      if (type == "checkSchoolType") {
        this.searchInfo.checkSchoolType = e;
      } else if (type == "is_tongzhao") {
        this.searchInfo.is_tongzhao = e;
      }
      this.submitForm("教育经历", e, undefined, type);
    },
    workExperienceChanged() {
      this.isOperation = true;
      this.submitForm("工作年限", this.searchInfo.workExperiences);
    },
    handEdit(e, index) {
      switch (e) {
        case "人才拥有者":
        case "人才类型":
        case "性别":
        case "婚姻状况":
        case "第一学历":
        case "求职状态":
        case "期望职位":
        case "职位":
        case "汇报对象":
        case "专业名称":
        case "微信或其他":
        case "学校类型":
        case "工作经验":
        case "年龄":
        case "期望月薪":
        case "下属人数":
          break;
        case "收藏夹":
          this.loadFavorList("人才");
          break;
        case "候选人标记":
          this.loadTagList("人才");
          break;
        case "行业":
        case "期望行业":
          this.loadColumnInList("行业");
          break;
        case "职能":
        case "职能基础":
          this.loadColumnPosList("职能");
          break;
        case "邮箱":
        case "手机号码":
          break;
        case "期望城市":
        case "现居住城市":
          this.loadColumnCityList("城市");
          break;
        default:
          break;
      }
    },
    radioClick(type, id, name, index, create) {
      this.isCreate = create;
      if (type == 1 || type == 2) {
        this.searchInfo.resumeType =
          this.searchInfo.resumeType == type ? "" : type;
        this.submitForm("候选人类型", this.searchInfo.resumeType);
      } else if (type == "男" || type == "女") {
        this.searchInfo.sex = this.searchInfo.sex == type ? "" : type;
        this.submitForm("性别", this.searchInfo.sex);
      }
      this.loadList();
    },
    clearHSearch() {
      this.isResouceShow = 0;
    },

    clearSearch() {
      this.searchInfo = {
        matchAll: true,
        certificate: "", //证书
        channels: [], //渠道
        checkSchoolType: false, //是否按学校类型搜索
        comment: "", //候选人备注
        company: "", //公司名称
        contact: "", //联系方式
        educations: ["UnLimit"], //教育经历 [本科及以上, 硕士及以上, 博士及以上, 大专及以上, 高中及以下]
        english: "", //语言能力
        favoriteIds: [], //收藏夹Id
        firstDegrees: [], //第一学历
        forwardLocations: [], //期望城市
        forwardTitle: "", //期望职位
        forwardVocation: "", //期望职能
        forwardVocations: [], //期望行业
        functionIds: [], //职能
        highAimSalary: "", //期望最多月薪
        id: "", //简历编号
        isAllCompany: false, //是否全部公司
        isAllTitle: false, //是否全部职位
        is_tongzhao: false, //是否统招
        jobStatus: [], //求职状态
        joinProjectName: "", //加入项目名称
        lowAimSalary: "", //期望最少月薪
        major: "", //专业名称
        married: [], //婚姻状况
        maxAge: "", //最大年龄
        minAge: "", //最小年龄
        nowLocations: [], //现居住城市
        ownerUserIds: [], //人才拥有者
        projectName: "", //项目名称
        reasonOfLeaving: "", //离职原因
        tagIds: [], //标签
        resumeType: "", //候选人类型：1-候选人，2-code call
        school: "", //学校名称
        sex: "", //性别
        title: "", //职位名称
        type: "", //列表样式
        userName: "", //姓名
        vocationIds: [], //行业Id
        workExperiences: ["UnLimit"], //工作年限 [UnLimit, OneToThree, ThreeToFive, FiveToTen, TenMore]
      };
      this.radioNowLocations = [];
    },
    nowLocationsChanged(e) {
      this.cascaderCityKey++;
      this.loadList();
    },
    orderSearch() {
      this.handleStatisticsResumeOperationOperate("订阅搜索", "列表");
      //订阅搜索
      this.subscribeSearchDialog = true;
    },
    // 数组中内容转字符串
    changArrOfNowLocations() {
      this.radioNowLocations = this.searchInfo.nowLocations.map((item) => {
        return (item = JSON.stringify(item));
      });
      this.submitForm("现居住城市", this.radioNowLocations);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    saveSubscribe(formName) {
      this.isSubscribeSearch = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subscribeForm.searchList = this.searchInfo;
          SearchAddOrEdit(this.subscribeForm).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.subscribeSearchDialog = false;
              this.loadOrderSearchList();
              this.resetForm("subscribeForm");
              this.isSubscribeSearch = false;
            }
          });
        } else {
          this.isSubscribeSearch = false;
          return false;
        }
      });
    },
    loadOrderSearchList() {
      GetSearchPage({ type: "人才" }).then((res) => {
        if (res.success) {
          this.OrderSearchList = res.result;
        }
      });
    },
    subscribeSearch() {
      this.subscribeSearchDialog = true;
    },
    blurTitle(e) {
      setTimeout(() => {
        this.isShowTitle = false;
      }, 500);
    },
    isMoreCityClk() {
      this.cascaderCityKey = 0;
    },
    // 点击按钮是否显示联系方式
    hideContact(row, index) {
      if (!row.isShow) {
        this.handleStatisticsResumeOperationOperate("查看卡片联系方式", "列表");
      }
      if (row.offerOrEntryProjectResumeId && !row.offerOrEntryPermission) {
        this.$message.error(
          "候选人处于【offer】或【入职】阶段，联系方式暂无法查看"
        );
      } else if (
        row.secure &&
        !this.hasMenuPage("resume:resumeList:seeSecure") &&
        !row.canViewSecure
      ) {
        this.$message.error("候选人处于【保密】中，联系方式暂无法查看");
      } else {
        this.ListData.splice(index, 1, {
          ...row,
          isShow: !row.isShow,
        });
      }
    },
    postion(val) {
      const div = document.createElement("div");
      div.innerHTML = val.company + (val.title ? " . " + val.title : "");
      this.postionTitle = div.innerText;
    },
    searchCus(val, type) {
      let params = {
        lastMenu: this.actived,
        clickMenuName: "",
      };
      this.superStep = this.actived;
      this.isShowDelBtn = false;
      if (this.actived != val) {
        this.actived = val;
        this.sortType = "";
        this.sortCheck = null;

        if (val == "导入记录") {
          this.$router.push({
            path: "/resume/importRecord",
          });
          params.clickMenuName = val;
          statisticsFrameClick(params).then();
          return;
        }
        if (val == "待处理人才") {
          this.$router.push({
            path: "/resume/pending",
          });
          params.clickMenuName = val;
          statisticsFrameClick(params).then();
          return;
        }
        switch (type) {
          case "quick": //快捷菜单
            if (val !== "所有人才") {
              this.handleStatisticsResumeOperationOperate(
                "左侧其他菜单",
                "列表"
              );
            }
            this.searchInfo.favoriteId = "";
            this.searchInfo.quickType = val;
            this.searchInfo.menuProperty = "快捷菜单";

            if (val == "导入记录") {
              this.isOperation = false;
              this.$router.push({
                path: "/resume/importRecord",
              });
            } else if (val == "待处理人才") {
              this.isOperation = false;
              this.$router.push({
                path: "/resume/importRecord",
              });
            } else {
              this.isOperation = true;
              this.$router.replace({
                path: "/supplierAllBack",
                query: {
                  path: "/resume",
                  status: val,
                },
              });
            }
            break;
          case "order":
            this.handleStatisticsResumeOperationOperate("订阅搜索", "列表");
            this.searchInfo.menuProperty = "订阅搜索";
            this.$router.push({
              path: "/resume",
              query: {
                order: val,
              },
            });
            break;
          case "folder":
            this.searchInfo.quickType = "所有人才";
            this.searchInfo.menuProperty = "文件夹";
            this.$router.push({
              path: "/resume",
              query: {
                folder: val,
              },
            });
            this.searchInfo.favoriteId = val;
            if (this.ListData.length) {
              this.isDisableDel = true;
              this.isShowDelBtn = true;
            }
            break;
          case "label":
            this.searchInfo.quickType = "所有人才";
            this.$router.push({
              path: "/resume",
              query: {
                label: val,
              },
            });
            this.searchInfo.customLabelIds = [val];
            this.loadList();
            break;
        }
      }
    },
    submitForm(type, val, entry, isEducationsType) {
      this.isOperation = true;
      if (type && val) {
        if (type == "期望月薪") {
          val =
            this.searchInfo.lowAimSalary + "-" + this.searchInfo.highAimSalary;
        } else if (type == "年龄") {
          val = this.searchInfo.minAge + "-" + this.searchInfo.maxAge;
        }
        this.getStatisticsResumeIndexPrecision(
          type,
          val,
          entry,
          isEducationsType
        );
      }
      this.searchKeyVal = this.searchInfo.searchKey;
      this.saveSearchVal.unshift(this.searchInfo.searchKey);
      if (this.searchInfo.searchKey && this.searchInfo.searchKey.length > 0) {
        this.dbClass.add = this.searchInfo.searchKey;
      }
      this.searchInfo.refresh = false;
      this.trimInput();
      this.assignment();
      this.isCreate = true;
      this.radioGroup = "";
      this.$refs["searchInfo"].validate((valid) => {
        if (valid) {
          this.pagination.pageNo = 1;
          this.showFavorBtn = false;
          this.searchInfo.recordId = "";
          this.loadList();
        }
      });
      this.handleStatisticsResumeOperationOperate("搜索", "列表");
    },
    //记录人才精确搜索；用户仅使用精确搜索模块中的内容进行检索，检索一次上报一次
    getStatisticsResumeIndexPrecision(type, val, entry, isEducationsType) {
      statisticsResumeIndexPrecision({
        searchKey:
          typeof val == "boolean"
            ? JSON.stringify(this.searchInfo.educations)
            : typeof val == "object"
            ? JSON.stringify(val)
            : val,
        type: type,
        entry: entry ? entry : "搜索按钮",
        is_tongzhao: isEducationsType == "is_tongzhao" ? true : "",
        checkSchoolType: isEducationsType == "checkSchoolType" ? true : "",
      }).then();
    },
    getTime(val) {
      this.timeVal = val;
      this.submitForm("人才创建时间", val[0] + "-" + val[1]);
    },
    getlastModifiedTime(val) {
      this.lastModifiedTime = val;
      this.submitForm("人才更新时间", val[0] + "-" + val[1]);
    },
    //3.失去焦点事件
    onInputBlur(e) {
      setTimeout(() => {
        if (e.type == "blur" || e.type == "keyup") {
          this.isShowFocus = false;
        }
      }, 500);
    },
    // 显示弹框
    showDetail(val, index) {
      this.isOperation = true;
      this.handleStatisticsResumeOperationOperate("查看详情", "列表");
      if (
        val.secure &&
        !this.hasMenuPage("resume:resumeList:seeSecure") &&
        !val.canViewSecure
      ) {
        this.$confirm("人才保密中，无法查看详情", "提示", {
          showConfirmButton: false,
          cancelButtonText: "关闭",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      let that = this;
      this.rank =
        (this.pagination.pageNo - 1) * this.pagination.pageSize + (index + 1);
      if (this.mouseoverDialog) {
        this.mouseoverDialog = false;
      }
      this.hoverInfo.hover = false;
      this.timeOut = setTimeout(() => {
        this.resumeId = val.id;
        this.searchKeyVal = this.searchInfo.searchKey;
        this.hoverInfo.matchAll = this.searchInfo.matchAll;
        this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
        if (!that.time) {
          if (that.time !== undefined) {
            this.mouseoverDialog = true;
            this.time = false;
            this.detailSearchInfo.ranks = this.rank;
            this.detailSearchInfo.resumeId = val.id;
            this.detailSearchInfo.operatedTime = new Date().Format(
              "yyyy-MM-dd hh:mm:ss"
            );
            this.detailSearchInfo.menuName = this.actived;
            this.detailSearchInfo.first =
              this.getResumeId == val.id ? false : true;
            this.detailSearchInfo.preCloseTime = this.getResumePreCloseTime;
            this.detailSearchInfo.menuType = this.$route.query.order
              ? "订阅搜索"
              : this.$route.query.folder
              ? "文件夹"
              : this.$route.query.label
              ? "个性化标签"
              : "快捷菜单";
            this.$store.dispatch("upDateResumeId", val.id);
          }
        }
        if (document.querySelector("#CONTAINER-CC-TOOLBAR")) {
          document.querySelector("#CONTAINER-CC-TOOLBAR").style.zIndex = 10001;
        }
      }, 1500);
      this.repeatId = true;
      this.openTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
    },
    resumeDigClose() {
      this.postStatisticsResumeSearchDetailDuration();
      this.mouseoverDialog = false;
    },
    trimInput(val) {
      if (val) {
        this.searchInfo.searchKey = val.trim();
      }
    },
    loadUserBase() {
      //获取用户列表
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.searchUserList = res.result;
        }
      });
    },
    focusShowStorage() {
      this.assignment();
      this.isShowFocus = true;
      statisticsFulltextIndex().then();
    },
    saveSearch() {},
    // 全网搜跳转
    linkToWebSearch() {
      this.handleStatisticsResumeOperationOperate("全网搜", "列表");
      statisticsNetworkWide().then();
      window.open(
        "http://osr.c43bc54606c844b1b82b017e4474a15a6.cn-shanghai.alicontainer.com/osr/login"
      );
    },
    loadList(ischange) {
      window.scrollTo(0, 0);
      //获取人才列表
      this.popVisible = true;
      this.$nextTick(() => {
        this.popVisible = false;
      });
      this.numbers = [];
      this.dialogResumeVisible = false;
      this.listLoading = true;
      this.dialogCusVisible = false;
      this.searchInfo.type = this.defaultType;
      if (ischange) {
        this.pagination.pageNo = this.pagination.pageNo;
      } else {
        this.pagination.pageNo = 1;
      }
      this.searchInfo.page = {
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: false,
      };
      this.searchInfo.createStartTime = this.timeVal ? this.timeVal[0] : "";
      this.searchInfo.createEndTime = this.timeVal ? this.timeVal[1] : "";
      this.searchInfo.lastModifiedStartTime = this.lastModifiedTime
        ? this.lastModifiedTime[0]
        : "";
      this.searchInfo.lastModifiedEndTime = this.lastModifiedTime
        ? this.lastModifiedTime[1]
        : "";
      if (this.$route.query.folder) {
        this.searchInfo.menuProperty = "文件夹";
      } else if (this.$route.query.order) {
        this.searchInfo.menuProperty = "订阅搜索";
      } else if (this.$route.query.label) {
        this.searchInfo.menuProperty = "个性化标签";
      } else {
        this.searchInfo.menuProperty = "快捷菜单";
      }
      selectAdvancePageList(this.searchInfo).then((res) => {
        if (res.success) {
          this.isIndeterminate = false;
          this.allChecked = false;
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
          this.searchInfo.hits = res.result.total;
          statisticsResumeIndex(this.searchInfo).then();
          if (this.$route.query.folder && this.ListData.length) {
            this.isDisableDel = true;
            this.isShowDelBtn = true;
            this.mSelection = false;
          }
          for (let i = 0; i < this.ListData.length; i++) {
            this.ListData[i].isShow = false;
            this.ListData[i].isSelect = false;

            this.$set(this.ListData[i], "showEditOfName", false);
            this.$set(this.ListData[i], "showEditOfSex", false);
            this.$set(this.ListData[i], "showEditOfCompany", false);
            this.$set(this.ListData[i], "showEditOfTitle", false);
            this.$set(this.ListData[i], "showEditOfEducation", false);
            this.$set(this.ListData[i], "showEditOfSchool", false);
          }
          this.detailSearchInfo.search = this.searchInfo;
          this.detailSearchInfo.searchFinishTime = res.timestamp;
          this.detailSearchInfo.searchResultCount = this.pagination.total;
        }
        this.listLoading = false;
      });
      this.getResumeDataCount();
    },
    //人才详情页停留时间statisticsResumeSearchDetailDuration
    postStatisticsResumeSearchDetailDuration() {
      this.searchInfo.type = this.defaultType;
      this.searchInfo.page = {
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: false,
      };
      this.searchInfo.createStartTime = this.timeVal ? this.timeVal[0] : "";
      this.searchInfo.createEndTime = this.timeVal ? this.timeVal[1] : "";
      this.searchInfo.lastModifiedStartTime = this.lastModifiedTime
        ? this.lastModifiedTime[0]
        : "";
      this.searchInfo.lastModifiedEndTime = this.lastModifiedTime
        ? this.lastModifiedTime[1]
        : "";
      let params = {
        search: this.searchInfo,
        closeTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        openTime: this.openTime,
        ranks: this.rank,
        resumeId: this.resumeId,
        searchResultCount: this.pagination.total,
      };
      statisticsResumeSearchDetailDuration(params).then();
    },
    //快捷菜单人才数量
    getResumeDataCount() {
      resumeDataCount().then((res) => {
        this.dataCount = res.result;
      });
    },
    test() {
      // 终止页面跳转
    },
    //打开新窗口
    goNewPage(id) {
      this.handleStatisticsResumeOperationOperate("新窗口打开", "列表");
      statisticsResumeNewWindow({ resumeId: id }).then();
      let newpage = this.$router.resolve({
        path: "/resume/detailPage",
        query: { resumeId: id },
      });
      window.open(newpage.href, "_blank");
    },
    // 显示隐藏侧边栏
    showleftContent(id) {
      this.handleStatisticsResumeOperationOperate("显示侧边栏", "详情");
      this.ListData1 = this.ListData;
      this.ListData1.forEach((i) => {
        if (i.id == id) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.isshowleftContent = !this.isshowleftContent;
    },
    //更新数据
    updata(id) {
      this.ListData1.forEach((i) => {
        if (i.id == id) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.detailShowEntry = "详情页侧边栏";
      this.isShowDetail = true;
      this.isShowDetail1 = false;
      this.time = true;
      this.mouseoverDialog = false;
      this.resumeId = id;
      this.searchKeyVal = this.searchInfo.searchKey;
      this.hoverInfo.matchAll = this.searchInfo.matchAll;
      this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
      this.hoverInfo.hover = true;
      this.$refs.resumeDetails.reflsh(id);
    },
    //人才库-路径操作
    handleStatisticsResumeOperationOperate(operateName, pageType) {
      statisticsResumeOperationOperate({
        operateId: this.operateData.operateId,
        operateName: operateName,
        pageType: pageType,
        search: this.searchInfo,
      }).then();
    },
    handleStatisticsClickFunctionEscape(nextStep) {
      let data = null;
      if (nextStep) {
        if (nextStep == this.superStep) {
          data = this.actived;
        } else {
          data = this.superStep;
        }
      } else {
        if (this.nextStep == this.superStep) {
          data = this.actived;
        } else {
          data = this.superStep;
        }
      }
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry:
          this.searchInfo.searchKey !== ""
            ? "人才列表（全文检索）"
            : "人才列表（常规搜索）",
        presentStep: nextStep ? "加入项目" : "人才",
        startTimeStamp: this.startTimeStamp,
        nextStep: nextStep ? nextStep : this.nextStep,
        superStep: data,
      }).then();
    },
    saveTags() {
      this.$refs.labelManagement.save();
    },
    //发人才激活邮件
    setEmail() {
      this.emailDrawer = true;
    },
  },
  mounted() {
    const _this = this;
    document.onkeydown = function (e) {
      //按下回车提交
      let key = window.event.keyCode;
    };
  },
  created() {
    this.startTimeStamp = Math.round(new Date().getTime() / 1000);
    if (!this.$route.query.order && !this.$route.query.folder) {
      this.isDisableDel = false;
      this.isShowDelBtn = false;
    } else if (this.$route.query.folder) {
      this.searchInfo.quickType = "所有人才";
      this.searchInfo.favoriteId = this.$route.query.folder;
      this.searchInfo.refresh = false;
    } else if (this.$route.query.order) {
      this.searchInfo.refresh = false;
    }
    if (this.$route.query.status) {
      this.searchInfo.quickType = this.$route.query.status;
    }
    if (this.$route.query.recordId) {
      this.searchInfo.recordId = this.$route.query.recordId;
    }
    if (this.$route.query.resumeId) {
      //显示项目详情
      this.isShowDetail = true;
      this.time = true;
      this.mouseoverDialog = false;
      this.resumeId = this.$route.query.resumeId;
      this.searchKeyVal = this.searchInfo.searchKey;
      this.hoverInfo.matchAll = this.searchInfo.matchAll;
      this.hoverInfo.majorSearch = this.searchInfo.majorSearch;
      this.hoverInfo.hover = true;
    }
    if (this.$route.query.type&&this.$route.query.type=='remark') {
      this.isShowRemark = true;
    }else{
      this.isShowRemark = false;
    }
    if (this.$route.query.status) {
      this.actived = this.$route.query.status;
    } else {
      this.operateData.operateId =
        this.getStore("uid") + "-" + new Date().getTime();
      this.actived = "所有人才";
      this.handleStatisticsResumeOperationOperate("人才库", "列表");
    }
    if (this.$route.query.order) {
      GetSearchById(this.$route.query.order).then((res) => {
        if (res.success) {
          this.searchInfo = res.result.searchList;
          this.loadList();
        }
      });
    } else {
      this.loadList(); // 列表默认加载
    }
    if (this.$route.query.t) {
      this.loadList(); // 列表默认加载
    }
    this.loadUserBase(); // 人才拥有者
    this.loadColumnCityList("城市"); //获取城市列表
    this.loadColumnInList("行业"); //获取行业列表
    this.loadColumnPosList("职能"); //获取职能列表
    this.loadOrderSearchList();
    this.getListFavorite();
    this.loadTagList("人才"); //获取标签列表
    this.getPersonalizedTags();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to, from, next);
    next((vm) => {
      vm.superStep = from.meta.name;
    });
  },
  destroyed() {
    if (this.actived == "所有人才") {
      this.handleStatisticsResumeOperationOperate("框架菜单", "列表");
    }
    // console.log(111)
    if (!this.isOperation) {
      this.nextStep = this.$route.meta.name;
      this.handleStatisticsClickFunctionEscape();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../collapsed.less";
.resumeName {
  cursor: pointer;
}
.fontColor1 {
  color: #999;
}
.input-with-select.appandSelect {
  vertical-align: middle !important;
  .el-input-group__append {
    background-color: #fff;
    width: 65px;
    border-radius: 0;
  }
  .el-select__caret {
    height: 32px;
    line-height: 32px;
  }
  .el-input-group__append:hover {
    border-color: #ededed !important;
  }
}
.input-with-select.appandSelect:hover {
  .el-input-group__append {
    border-color: #ededed !important;
  }
}
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
    margin-bottom: 15px;
  }
}
.shadowCard:hover {
  #triangle-topleft {
    display: inline-block;
  }
}

#triangle-topleft {
  display: none;
  width: 0;
  height: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  border-top: 30px solid #e7e7e7;
  border-right: 35px solid transparent;
  padding-top: 3px;
  padding-left: 2px;
}
#triangle-topleft::before {
  content: "阅";
  color: #b7b7b7;
  left: 2px;
  font-family: element-icons !important;
  position: relative;
  top: -34px;
  font-size: 12px;
}
.searchL {
  width: calc(100% - 162px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input-group__prepend) {
    border-left: 1px solid #e60012;
    border-top: 1px solid #e60012;
    border-bottom: 1px solid #e60012;
    line-height: 33px;
    background-color: white;
  }
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
    border-right: none;
    border-left: none;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }

  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tableType {
  display: inline-block;
}
.comList {
  max-height: 400px;
  overflow: auto;
  li {
    height: 32px;
    line-height: 32px;
    padding: 0 35px 0 15px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    .comName {
      width: 80%;
      float: left;
      p {
        max-width: 60%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        height: 20px;
        width: 62px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        color: #efa14d;
        border: 1px solid #efa14d;
        border-radius: 3px;
        margin-left: 8px;
      }
    }
  }
}

.loacalStorage {
  border: 1px solid #ededed;
  width: calc(100% - 55px);
  // width: calc(79% - 350px);

  // @media (max-width: 1688px) {
  //   width: calc(71% - 352px);
  // }
  z-index: 10;
  // padding: 15px;
  background: white;
  position: absolute;
  // top: 55px;
  // left: 15px;
}
.radioGroupBtn {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0 !important;
  .el-checkbox-button {
    box-shadow: none !important;
    .el-checkbox-button__inner {
      border-radius: 0;
      border: 0;
      padding: 7px 10px;
      color: #666;
      font-size: 14px !important;
    }
  }
  .el-checkbox-button.is-checked {
    .el-checkbox-button__inner {
      color: #fff;
    }
  }
}
.clearfix {
  .resumeCard:nth-child(1) {
    border-top: 1px solid #ebeef5;
  }
}
.resumeIndex {
  .linkButton {
    font-size: 13px;
    color: #999 !important;
  }
  .tag {
    margin-right: 5px;
  }
}
.iconHover:hover {
  cursor: pointer;
  color: #ff3f3f !important;
  .fontColor,
  i {
    color: #ff3f3f !important;
  }
}
.footBtns {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 100%;
  padding: 30px 15px;
  background: #fff;
}
.garyColor {
  color: #333;
  font-size: 13px;
}
.garyColor1 {
  color: #898989;
  font-size: 13px;
}
:deep(.el-loading-mask) {
  left: 15px;
  right: 15px;
}
.isRead {
  .tabHref {
    color: #666 !important;
  }
}
</style>

<style scoped>
.searchInfo :deep(.el-select .el-tag) {
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
}
.searchInfo :deep(.el-cascader__tags .el-tag) {
  overflow: hidden;
  max-width: 77px;
  text-overflow: ellipsis;
}
.searchInfo :deep(.el-popper) {
  width: 260px;
}
.searchInfo :deep(.el-input__suffix) {
  right: 10px;
}
.searchInfo .el-col-8 {
  padding-left: 0 !important;
}
.btnHover {
  color: rgb(51, 51, 51);
  font-size: 13px;
  cursor: pointer;
}
.btnHover:hover {
  color: rgb(41, 121, 255) !important;
  text-decoration: none;
  background: #f0f0f0;
}
.changeButtonStyle {
  padding: 6px;
  color: white;
  background: #ff3f3f;
  border: 1px #ff3f3f solid;
  margin-top: -1px;
  border-radius: 0 4px 4px 0;
}
.content-container :deep(.el-input .el-select .el-input) {
  width: 100px;
  padding-left: 8px;
}
.labelAppend :deep(.el-input-group__append) {
  border: none;
  padding: 0;
  width: 32px;
  background-color: white;
  position: absolute;
  right: 0px;
  top: 1px;
}
.labelAppend :deep(.el-input-group--append .el-input__inner) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.labelAppend1 :deep(.el-input-group--append .el-input__inner) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.labelAppend1 :deep(.el-input-group__append) {
  border: none;
  /* padding: 0; */
  /* width: 28px; */
  background-color: white;
  position: absolute;
  right: 9px;
  top: 2px;
}
.table-top {
  margin-bottom: 10px;
  margin-top: 10px;
}

.el-card {
  border-top: 0;
  border-radius: 0;
}
.el-card :deep(.el-checkbox) {
  display: flex;
  align-items: center;
}
.el-card :deep(.el-checkbox__input) {
  margin: 0 14px 0 -6px;
}
.el-card :deep(.el-checkbox__label) {
  width: calc(100% - 25px);
}
.el-card:hover {
  background: #f5f7fa;
  box-shadow: none;
}

.contactInformationBoreder {
  padding-bottom: 15px;
  /* margin-left: 20px; */
}
.contactInformation,
.workStatus {
  padding-left: 0 !important;
}
.workStatus {
  width: calc(100% - 54px);
  padding: 5px 0 10px !important;
  /* margin-left: 22px;
  margin-right: 22px; */
  /* border-top: 1px solid #efefef; */
}
.order {
  /* padding: 0 8px; */
  color: #606266;
  cursor: pointer;
}
.order:hover {
  color: #cf0010 !important;
}
.hoverColorChange {
  color: #606266;
  cursor: pointer;
  font-size: 13px;
}
.hoverColorChange:hover {
  color: #cf0010 !important;
}
.searchBox :deep(.el-checkbox-button__inner) {
  border: 0;
  background: #fafafa;
  color: #606266;
  box-shadow: none;
  font-size: 13px !important;
  padding: 7px 10px;
}
.searchBox :deep(.el-checkbox-button__inner:hover) {
  color: #e60012 !important;
}
.searchBox :deep(.el-form-item__label) {
  font-size: 13px !important;
}
.searchBox {
  padding: 0 !important;
}
.bodTop {
  border-top: 30px solid #efefef !important;
}
.personFoo {
  padding: 15px 0 0 0;
}
.searchRow :deep(.el-input .el-select .el-input) {
  width: 110px;
  padding-left: 10px;
}
.searchRow {
  padding-left: 0 !important;
}
.shadowCard :deep(.el-card__body) {
  padding: 0 20px 15px !important;
  position: relative;
  display: flex;
}
</style>

<style  lang="scss">
.dialogFloatwindow.el-dialog__wrapper {
  left: auto;
  box-shadow: -4px 0 15px -1px #dcdcdc;
}
.floatwindow {
  width: 75%;
  margin-top: 0 !important;
  margin-right: 0;
  height: 100vh;
  @media (max-width: 1366px) {
    width: 1000px;
    .el-dialog__header {
      width: 1000px !important;
    }
  }
  .el-dialog__header {
    position: fixed;
    top: 0;
    width: 75%;
    background: #fff;
    color: #333;
    z-index: 999;
  }
  .el-dialog__headerbtn {
    right: 45px;
  }
  .el-dialog__body {
    padding: 0;
    margin-top: 40px;
    @media (max-width: 1366px) {
    }
  }
  .drawerTitle {
    font-weight: bold;
    .drawIcon {
      color: #898989;
      font-weight: normal;
      vertical-align: bottom;
      margin-right: 8px;
    }
  }
}
.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}
.l_box {
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ededed;
    background: #ededed;
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: #fff;
  }
}
.l_content {
  width: 200px;
  border-right: 1px #ececec solid;
  border-bottom: 1px #ececec solid;
  overflow: auto;
  height: calc(100vh - 120px);
  .l_c_tit {
    font-weight: bold;
    font-size: 15px;
    line-height: 30px;
    padding: 5px 20px;
    border-bottom: 1px #ececec solid;
  }
  .l_c_list {
    p {
      color: #606266;
      line-height: 25px;
      padding: 5px 20px;
    }
    p:hover {
      color: #526ecc;
      background: #ececec;
      text-decoration: none;
    }
  }
  .l_c_list_active {
    color: #526ecc !important;
    background: #ececec;
    text-decoration: none;
  }
}
.redrawerClass {
  .el-drawer__body {
    overflow: hidden;
  }
}
</style>
