<template>
  <section>
    <div class="drawerCon_from" style="border: 0">
      <el-form
        @submit.native.prevent
        :model="from"
        ref="from"
        :rules="rules"
        label-width="130px"
        class="addForm"
      >
        <el-row :gutter="24">
          <el-form-item label="收件人：" class="is-required">
            <el-input
              v-model.trim="from.resume"
              style="width: 90%"
              disabled
            ></el-input>
            <p
              style="
                color: #999;
                margin: 5px 0 0;
                font-size: 12px;
                line-height: 1;
              "
            >
              自动带出勾选对象的邮箱
            </p>
          </el-form-item>
        </el-row>
        <el-row :gutter="24">
          <el-form-item label="邮件标题：" prop="title">
            <el-input
              v-model.trim="from.title"
              maxlength="100"
              style="width: 90%"
              @blur="getDetail"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row :gutter="24">
          <el-form-item label="邮件预览区：">
            <div v-html="detail" class="htmlContent"></div>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button
        type="primary"
        size="mini"
        :loading="saveloading"
        @click="save"
        style="margin-right: 15px"
        >{{ saveloading ? "发送中···" : "发送" }}</el-button
      >
    </div>
  </section>
</template>

<script>
import {
  candidateActiveSend, //发送人才激活邮件
  candidateActivePreview, //预览邮件内容人才激活邮件
} from "../../api/api";
export default {
  props: ["mSelection"],
  data() {
    return {
      from: {
        resumeIds: [],
        resume: "",
        title: "",
      },
      rules: {
        // resume: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入收件人",
        //   },
        // ],
        title: [{ required: true, message: "请输入邮件标题", trigger: "blur" }],
      },
      detail: null,
      saveloading: false,
    };
  },
  created() {
    let list = [];
    this.mSelection.forEach((i) => {
      list.push(i.email);
      this.from.resumeIds.push(i.id);
    });
    this.from.resume = list.join(" ");
  },
  methods: {
    //标题失去焦点获取预览
    getDetail(val) {
      if (this.from.title.length == 0) {
        // this.$message.error("请输入标题");
        return false;
      }
      candidateActivePreview({
        resumeIds: this.from.resumeIds,
        title: this.from.title,
      }).then((res) => {
        this.detail = res;
      });
    },
    //发送
    save() {
      return new Promise((resole) => {
        this.$refs["from"].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            candidateActiveSend({
              resumeIds: this.from.resumeIds,
              title: this.from.title,
            }).then((res) => {
              if (res.success) {
                this.$message.success("成功");
                this.saveloading = false;
                this.$parent.handleClose();
              }
              resole();
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.htmlContent {
  width: 90%;
  min-height: 600px;
  border-radius: 4px;
  border: 1px solid rgb(236, 236, 236);
}
</style>
