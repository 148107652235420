export default class Db {
    constructor({
        dbKey = (Math.random() * Date.now()).toString(16),
        limit = 5
                } = {}) {
        this.dbKey = dbKey;
        this.limit = limit;
    }

    // 获取所有
    get all() {
        return JSON.parse(localStorage.getItem(this.dbKey)) || [];
    }

    // 新增内容
    set add(keyword) {
        const currentAll = this.all;
        const index = currentAll.findIndex(a => a === keyword);

        if (index === -1) {
            currentAll.unshift(keyword)
        } else {
            currentAll.splice(index, 1);
            currentAll.unshift(keyword);
        }

        localStorage.setItem(this.dbKey, JSON.stringify(currentAll));
    }

    // 获取制定内容
    get list() {
        return this.all?.slice(0, this.limit)
    }

    get clear() {
        localStorage.removeItem(this.dbKey);
    }
}
